import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

const TimeAgoDisplay = (props) => {
  const { date, className } = props;

  const { t } = useTranslation(['general']);

  const timeSince = useMemo(() => {
    if (!date) return '';

    const seconds = Math.floor((new Date() - new Date(date)) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
      return `${t('general:years_ago', { time: Math.floor(interval) })}`;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return `${t('general:months_ago', { time: Math.floor(interval) })}`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return `${t('general:days_ago', { time: Math.floor(interval) })}`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return `${t('general:hours_ago', { time: Math.floor(interval) })}`;
    }
    interval = seconds / 60;
    if (interval > 1) {
      return `${t('general:minutes_ago', { time: Math.floor(interval) })}`;
    }
    return `${t('general:seconds_ago', { time: Math.floor(interval) })}`;
  });

  return (
    <p
      className={className}
    >
      { timeSince }
    </p>
  );
};

TimeAgoDisplay.propTypes = {
  date: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TimeAgoDisplay.defaultProps = {
  className: '',
};

export default TimeAgoDisplay;
