import withAuthentication from 'components/hocs/withAuthentication';
import React from 'react';

// hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// HOCs
import TwitchConnection from './TwitchConnection';

const ConnectionsHub = () => {
  const { connections } = useSelector((state) => state.session);

  const { t } = useTranslation(['general']);

  return (
    <div className="is-min-fullheight">
      <div className="columns is-centered is-vcentered has-fullheight">
        <div className="column is-8-desktop my-6">
          <div className="box p-5 boxshadow has-background-primary">

            <h1 className="has-text-white has-text-weight-bold mb-3">
              {`${t('general:connections')} (Beta)`}
            </h1>
            <p className="has-text-white  mb-3">
              {`${t('general:connection_explanation')}`}
            </p>
            <div className="has-border-top-grey mb-4" />

            <div>
              <TwitchConnection />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuthentication(ConnectionsHub);
