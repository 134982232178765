import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import { Link } from 'react-router-dom';
import Footer from 'components/layout/Footer';

// Constants
import { LANDING } from 'constants/routes';

const NotFound = () => {
  const { t } = useTranslation(['errors']);

  return (
    <div className="hero is-min-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          <p className="has-text-centered has-text-danger has-text-weight-bold is-size-4">
            {`${t('errors:error_profile_not_found')}.`}
          </p>
          <Link
            className="button mt-4 has-text-black has-background-primary has-text-weight-bold"
            to={LANDING}
          >
            {t('general:go_back')}
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
