import React, { useEffect, useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import useAPI from 'hooks/useAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

// Constants
import { CONNECTIONS, LOGIN } from 'constants/routes';

// Actions
import { setTwitchState, setTwitchConnection } from 'store/profile';

// Components
import Loader from 'components/utils/Loader';

const TwitchConnectionResponseHandler = () => {
  const { t } = useTranslation(['errors']);

  const { search } = useLocation();
  const history = useHistory();

  const [error, setError] = useState('');

  const { post } = useAPI();

  const { state } = useSelector((_state) => _state.profile.connections.twitch);

  const dispatch = useDispatch();

  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated || !search) return;

    const {
      code, state: incomingState, scope, error: requestError,
    } = new Proxy(new URLSearchParams(search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if (requestError === 'access_denied') {
      setError(t('errors:error_twitch_connection_access_denied'));
      return;
    }

    if (requestError) {
      setError(t('errors:error_twitch_connection_request'));
      return;
    }

    // check if returned state has the state we created
    if (!code || !state || incomingState !== state) {
      history.push(CONNECTIONS);
      return;
    }

    dispatch(setTwitchState(code));

    history.push(LOGIN);
  }, [isAuthenticated]);

  useEffect(() => {
    const prepareConnection = async () => {
      try {
        const data = {
          code: state,
        };

        const connection = await post('/connections/twitch/connect', data);

        dispatch(setTwitchConnection(connection));

        history.push(CONNECTIONS);
      } catch (e) {
        setError(t('errors:error_twitch_connection_request'));
      }
    };

    if (!isAuthenticated) return;

    if (!state) {
      history.push(CONNECTIONS);
      return;
    }

    prepareConnection();
  }, [isAuthenticated]);

  return (
    <Loader error={error} />
  );
};

export default TwitchConnectionResponseHandler;
