import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Constants
import { TYPE_RIOT_ID } from 'constants/contacts';

// Hooks
import { useTranslation } from 'react-i18next';

// Assets
import img from 'assets/images/icons/riot_icon.png';
import img_display from 'assets/images/icons/riot_icon_display.png';

// Components
import ContactField from '../../ContactField';
import ContactButton from '../../ContactButton';
import ContactDisplay from '../../ContactDisplay';

const RiotContact = (props) => {
  const { index } = props;

  const { t } = useTranslation(['errors']);

  const validate = (tag) => {
    let error = '';
    if (tag.length > 32) {
      error = t('errors:error_enter_valid_tag');
    }

    return { error, tag };
  };

  return (
    <ContactField
      icon={<img src={img_display} alt="" className="has-width-40" />}
      index={index}
      validate={validate}
      label="Riot ID"
      placeholder="Name#EUW"
    />
  );
};

RiotContact.propTypes = {
  index: PropTypes.number.isRequired,
};

export const RiotDisplay = (props) => {
  const { index } = props;
  /*
  const { contact } = useSelector((state) => state.profile);
  const { tag } = contact[index];
  const [name, location] = tag.split('#');
  <div className="has-border-white-light">
    <a
      href={`https://op.gg/summoner/userName=${tag}`}
      target="_blank"
      rel="noreferrer"
      className="has-text-white has-text-weight-bold is-block"
    >
      League of Legends
    </a>
    <a
      href={`https://valorant.op.gg/profile/name=${name}&tagLine=${location}`}
      target="_blank"
      rel="noreferrer"
      className="has-text-white has-text-weight-bold is-block"
    >
      Valorant
    </a>
  </div>
  */

  return (
    <div className="mb-3">
      <ContactDisplay
        icon={<img src={img_display} alt="" className="has-width-40" />}
        index={index}
        link="https://riot.com"
        label="Riot ID"
      />
    </div>
  );
};

RiotDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export const AddRiotContact = () => <ContactButton label="Riot Games" type={TYPE_RIOT_ID} img={img} />;

export default RiotContact;
