export const MAX_LINK_LENGTH = 64;
export const MAX_NAME_LENGTH = 64;
export const MAX_DESC_LENGTH = 140;
export const MAX_CONTACT_LENGTH = 32;
export const MAX_CONTACT_NAME_LENGTH = 140;

export const TYPE_DISCORD_ID = 'DISCORD_ID';
export const TYPE_DISCORD_SERVER = 'DISCORD_SERVER';
export const TYPE_STEAM_CODE = 'STEAM_CODE';
export const TYPE_STEAM_ID = 'STEAM_ID';
export const TYPE_RIOT_ID = 'RIOT_ID';
export const TYPE_BATTLENET_ID = 'BATTLENET_ID';
export const TYPE_EPIC_ID = 'EPIC_ID';
export const TYPE_TWITCH_NAME = 'TWITCH_NAME';
export const TYPE_UBISOFT_NAME = 'UBISOFT_NAME';
export const TYPE_INSTAGRAM_ID = 'INSTAGRAM_ID';
export const TYPE_TWITTER_ID = 'TWITTER_ID';
export const TYPE_YOUTUBE_ID = 'YOUTUBE_ID';
export const TYPE_ORIGIN_ID = 'ORIGIN_ID';
export const TYPE_TIKTOK_ID = 'TIKTOK_ID';
export const TYPE_XBOX_ID = 'XBOX_ID';
export const TYPE_PSN_ID = 'PSN_ID';
export const TYPE_EMAIL = 'EMAIL';
export const TYPE_NINTENDO_SWITCH_ID = 'NINTENDO_SWITCH_ID';
export const TYPE_JUKEDGG_ID = 'JUKED_GG';
export const TYPE_LIQUIPEDIA_ID = 'LIQUIPEDIA_ID';
export const TYPE_WEBSITE_TAG = 'WEBSITE_TAG';
export const TYPE_POKEMON_GO_ID = 'POKEMON_GO_ID';
export const TYPE_SNAPCHAT_ID = 'SNAPCHAT_ID';

// Connections
export const TYPE_TWITCH_CONNECTION = 'TWITCH_CONNECTION';

export default {
  TYPE_DISCORD_ID,
  TYPE_DISCORD_SERVER,
  TYPE_STEAM_CODE,
  TYPE_STEAM_ID,
  TYPE_RIOT_ID,
  TYPE_BATTLENET_ID,
  TYPE_EPIC_ID,
  TYPE_UBISOFT_NAME,
  TYPE_ORIGIN_ID,
  TYPE_XBOX_ID,
  TYPE_PSN_ID,
  TYPE_NINTENDO_SWITCH_ID,
  TYPE_JUKEDGG_ID,
  TYPE_LIQUIPEDIA_ID,
  TYPE_POKEMON_GO_ID,

  TYPE_TWITCH_NAME,
  TYPE_INSTAGRAM_ID,
  TYPE_TWITTER_ID,
  TYPE_YOUTUBE_ID,
  TYPE_TIKTOK_ID,
  TYPE_EMAIL,
  TYPE_WEBSITE_TAG,
  TYPE_SNAPCHAT_ID,

  TYPE_TWITCH_CONNECTION,
};
