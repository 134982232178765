import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Constants
import { TYPE_STEAM_ID } from 'constants/contacts';

// Hooks
import { useSelector } from 'react-redux';
import useAPI from 'hooks/useAPI';
import { useTranslation } from 'react-i18next';

// Assets
import img from 'assets/images/icons/steam_icon.png';
import img_display from 'assets/images/icons/steam_icon_display.png';

// Components
import Popup from 'reactjs-popup';
import ContactField from '../../ContactField';
import ContactButton from '../../ContactButton';
import ContactDisplay from '../../ContactDisplay';
import ContactLink from '../../ContactLink';
import ContactCopy from '../../ContactCopy';

const SteamIDContact = (props) => {
  const { index } = props;

  const { t } = useTranslation(['contact', 'errors']);

  const [disabled, setDisabled] = useState(false);

  const { get } = useAPI();

  const getSteamID = async (name) => {
    try {
      setDisabled(true);
      const user = await get(`/steam/id/${name}`);
      setDisabled(false);

      stats.push(['trackEvent', 'Steam Contact', 'Converted URL to ID']);

      return user || '';
    } catch (e) {
      setDisabled(false);

      return '';
    }
  };

  const validate = async (tag) => {
    const regex = /^[0-9]*$/;

    let error = '';

    // get the steam id from the steam url
    if (tag.startsWith('https://steamcommunity.com/')) {
      if (tag.includes('/profiles/')) {
        tag = tag.split('/profiles/')[1] || '';
        tag = tag.replace('/', '');
      } else if (tag.includes('/id/')) {
        tag = tag.split('/id/')[1] || '';
        tag = tag.replace('/', '');
        tag = await getSteamID(tag);
      }
    }

    if (regex.test(tag) === false) {
      error = t('errors:error_enter_valid_tag');
    }

    return { error, tag };
  };
  const label = (
    <>
      <p className="is-inline mr-2">Steam ID</p>
      <Popup
        trigger={(
          <div className="cleanButton is-inline">
            <i className="fas fa-question-circle has-text-white is-size-7 has-hover-icon" />
          </div>
        )}
        position="top center"
        on={['hover']}
        keepTooltipInside="#root"
        repositionOnResize
        className=""
      >
        {
        () => (
          <div className="has-max-width-200 has-text-weight-semibold p-2 has-text-white has-background-primary has-border-white-light br-5">
            <p className="is-size-7">
              { t('contact:steam_enter_id_or_url')}
            </p>
          </div>
        )
      }
      </Popup>
    </>
  );

  return (
    <>
      <ContactField
        icon={<img src={img_display} alt="" className="has-width-40" />}
        index={index}
        validate={validate}
        disabled={disabled}
        label={label}
        placeholder="12345678"
      />

    </>
  );
};

SteamIDContact.propTypes = {
  index: PropTypes.number.isRequired,
};

export const SteamIDDisplay = (props) => {
  const { contact, theme } = useSelector((state) => state.display);
  const { index } = props;

  const { tag } = contact[index];

  /*
  <div className="has-border-white-light">
    <a
      href={`https://csgostats.gg/player/${tag}`}
      target="_blank"
      rel="noreferrer"
      className="has-text-white has-text-weight-bold is-block"
    >
      CSGO Stats
    </a>
  </div>
  */

  return (
    <div>
      <ContactDisplay
        icon={<img src={img_display} alt="" className="has-width-40" />}
        index={index}
        label="Steam ID"
        link={`https://steamcommunity.com/profiles/${tag}`}
      >
        <ContactLink url={`https://steamcommunity.com/profiles/${tag}`} color={theme.text.color} />
        <ContactCopy text={`https://steamcommunity.com/profiles/${tag}`} color={theme.text.color} />
      </ContactDisplay>
    </div>
  );
};

SteamIDDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export const AddSteamIDContact = () => (
  <ContactButton type={TYPE_STEAM_ID} label="Steam" img={img} />
);

export default SteamIDContact;
