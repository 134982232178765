import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { TYPE_POKEMON_GO_ID } from 'constants/contacts';

// Assets
import img from 'assets/images/icons/pokemon_go_icon.png';
import img_display from 'assets/images/icons/pokemon_go_icon_display.png';

// Components
import ContactField from '../../ContactField';
import ContactButton from '../../ContactButton';
import ContactDisplay from '../../ContactDisplay';

const PokemonGoContact = (props) => {
  const { index } = props;

  const { t } = useTranslation(['errors']);

  const validate = (tag) => {
    let error = '';
    if (tag.length > 256) {
      error = t('errors:error_enter_valid_tag');
    }

    return { error, tag };
  };

  return (
    <ContactField
      icon={<img src={img_display} alt="" className="has-width-40" />}
      index={index}
      validate={validate}
      label="Pokemon Go"
      placeholder="Code"
    />
  );
};

PokemonGoContact.propTypes = {
  index: PropTypes.number.isRequired,
};

export const PokemonGoDisplay = (props) => {
  const { index } = props;

  return (
    <ContactDisplay
      icon={<img src={img_display} alt="" className="has-width-40" />}
      index={index}
      link="https://pokemongolive.com"
      label="Pokemon Go"
    />
  );
};

PokemonGoDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export const AddPokemonGoContact = () => <ContactButton label="Pokemon Go" type={TYPE_POKEMON_GO_ID} img={img} />;

export default PokemonGoContact;
