import React, { useState, useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Constants
import * as routes from 'constants/routes';

// Components
import SignOutButton from 'components/auth/SignOutButton';

// Assets
import logo from 'assets/images/base/logo.png';

// Styles
import styles from './styles.module.scss';

const Navigation = () => {
  const [isActive, setIsActive] = useState(false);

  const { isAuthenticated } = useAuth0();

  const toggleNavbarMobile = () => {
    setIsActive(!isActive);
  };

  const hideNavbar = () => {
    setIsActive(false);
  };

  if (!isAuthenticated) {
    return (
      <NavigationNoAuth
        isActive={isActive}
        toggleNavbarMobile={toggleNavbarMobile}
        hideNavbar={hideNavbar}
      />
    );
  }

  return (
    <NavigationAuth
      isActive={isActive}
      toggleNavbarMobile={toggleNavbarMobile}
      hideNavbar={hideNavbar}
    />
  );
};

const NavigationNoAuth = (props) => {
  const { t } = useTranslation(['general']);
  const { loginWithRedirect } = useAuth0();
  const { isActive, toggleNavbarMobile, hideNavbar } = props;

  const location = useLocation();

  const showNavbar = useMemo(() => {
    if (!Object.values(routes).includes(location.pathname)) {
      return false;
    }

    return true;
  }, [location]);

  if (showNavbar === false) return null;

  return (
    <nav className="navbar has-background-primary has-border-dark-grey-bottom" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link
          to={routes.LANDING}
          className="navbar-item"
          onClick={hideNavbar}
        >
          <img src={logo} alt="" className="has-width-32 has-height-32 mr-2" />
          <p className="has-text-weight-bold has-text-white">TAGS.GG</p>
        </Link>
        <button
          className={`navbar-burger mr-2 align-self-center has-text-black button has-background-primary ${isActive ? 'is-active' : ''}`}
          aria-label="menu"
          aria-expanded="false"
          type="button"
          onClick={toggleNavbarMobile}
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </button>
      </div>
      <div className={`has-background-primary navbar-menu ${isActive ? ' is-active' : ''} ${styles.mobile}`}>
        <div className="navbar-start has-text-centered">
          <div className="navbar-item p-0">
            <Link
              to={routes.DEMO}
              className="navbar-item has-text-white has-text-weight-bold"
              onClick={hideNavbar}
            >
              {t('general:editor')}
            </Link>
          </div>
        </div>
        <div className="navbar-end has-text-centered">
          <div className="navbar-item p-0">
            <a
              target="_blank"
              rel="noreferrer"
              className="cleanButton is-inline-block grow p-3"
              href="https://twitter.com/tags_gg"
            >
              <i className="fab fa-twitter has-text-white" />
            </a>
          </div>
          <div className="navbar-item">
            <button
              className="button has-background-secondary has-text-weight-bold is-inline-block"
              type="button"
              onClick={() => loginWithRedirect()}
            >
              {t('general:sign_in')}
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

NavigationNoAuth.propTypes = {
  isActive: PropTypes.bool.isRequired,
  toggleNavbarMobile: PropTypes.func.isRequired,
  hideNavbar: PropTypes.func.isRequired,
};

const NavigationAuth = (props) => {
  const { t } = useTranslation(['general']);
  const { isActive, toggleNavbarMobile, hideNavbar } = props;

  return (
    <nav className="navbar has-background-primary has-border-dark-grey-bottom" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link
          to={routes.LANDING}
          className="navbar-item"
        >
          <img src={logo} alt="" className="has-width-32 has-height-32 mr-2" />
          <p className="has-text-weight-bold has-text-white">TAGS.GG</p>
        </Link>
        <button
          className={`navbar-burger mr-2 align-self-center has-background-primary has-text-black button ${isActive ? 'is-active' : ''}`}
          aria-label="menu"
          onClick={toggleNavbarMobile}
          aria-expanded="false"
          type="button"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </button>
      </div>
      <div className={`navbar-menu has-background-primary is-paddingless ${isActive ? 'is-active' : ''} ${styles.mobile}`}>
        <div className="navbar-start has-text-centered has-text-weight-bold">
          <Link
            className="navbar-item has-text-white mx-1 my-1"
            to={routes.HOME}
            onClick={hideNavbar}
          >
            {t('general:profile')}
          </Link>
          <Link
            className="navbar-item has-text-white mx-1 my-1"
            to={routes.CONNECTIONS}
            onClick={hideNavbar}
          >
            {t('general:connections')}
          </Link>
        </div>
        <div className="navbar-end has-text-centered">
          <div className="navbar-item p-0">
            <a
              target="_blank"
              rel="noreferrer"
              className="cleanButton is-inline-block grow p-3"
              href="https://twitter.com/tags_gg"
            >
              <i className="fab fa-twitter has-text-white" />
            </a>
          </div>
          <div className="navbar-item has-text-centered has-text-white has-text-weight-bold is-hidden-touch">
            <p>
              {`v${process.env.REACT_APP_VERSION}`}
            </p>
          </div>
          <div className="navbar-item has-text-centered">
            <SignOutButton />
          </div>
        </div>
      </div>
    </nav>
  );
};

NavigationAuth.propTypes = {
  isActive: PropTypes.bool.isRequired,
  toggleNavbarMobile: PropTypes.func.isRequired,
  hideNavbar: PropTypes.func.isRequired,
};

export default Navigation;
