import React, { useMemo } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { swapContact } from 'store/profile';

// Constants
import TYPES from 'constants/contacts';

// Components
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  BattleNetContact, DiscordContact, DiscordServerContact, RiotContact, SteamIDContact, EpicContact, TwitchContact, UbisoftContact, YoutubeContact, TwitterContact, InstagramContact, OriginContact, TikTokContact, XBoxContact, PSNContact, EmailContact, NintendoSwitchContact, JukedGGContact, LiquipediaContact, WebsiteTagContact, TwitchConnectionContact, PokemonGoContact, SnapchatContact,
} from 'components/contact/Contacts';

// Styles
import styles from './styles.module.scss';

const ContactEditor = () => {
  const { contact, theme } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const { index: start } = source;
    const { index: end } = destination;

    dispatch(swapContact({ start, end }));

    if (!contact[start] || !contact[end]) return;

    const typeStartTag = contact[start].type;
    const typeEndTag = contact[end].type;

    stats.push(['trackEvent', 'Profile Editor', `Swapped ${typeStartTag} with ${typeEndTag}`]);
  };

  const renderedContacts = useMemo(() => contact.map((item, index) => {
    const { type, tag, tagType } = item;

    let renderedContact = null;

    switch (type) {
      case TYPES.TYPE_BATTLENET_ID:
        renderedContact = <BattleNetContact index={index} key={index} />;
        break;
      case TYPES.TYPE_STEAM_ID:
        renderedContact = <SteamIDContact index={index} key={index} />;
        break;
      case TYPES.TYPE_RIOT_ID:
        renderedContact = <RiotContact index={index} key={index} />;
        break;
      case TYPES.TYPE_EPIC_ID:
        renderedContact = <EpicContact index={index} key={index} />;
        break;
      case TYPES.TYPE_DISCORD_ID:
        renderedContact = <DiscordContact index={index} key={index} />;
        break;
      case TYPES.TYPE_DISCORD_SERVER:
        renderedContact = <DiscordServerContact index={index} key={index} />;
        break;
      case TYPES.TYPE_UBISOFT_NAME:
        renderedContact = <UbisoftContact index={index} key={index} />;
        break;
      case TYPES.TYPE_ORIGIN_ID:
        renderedContact = <OriginContact index={index} key={index} />;
        break;
      case TYPES.TYPE_XBOX_ID:
        renderedContact = <XBoxContact index={index} key={index} />;
        break;
      case TYPES.TYPE_PSN_ID:
        renderedContact = <PSNContact index={index} key={index} />;
        break;
      case TYPES.TYPE_NINTENDO_SWITCH_ID:
        renderedContact = <NintendoSwitchContact index={index} key={index} />;
        break;
      case TYPES.TYPE_JUKEDGG_ID:
        renderedContact = <JukedGGContact index={index} key={index} />;
        break;
      case TYPES.TYPE_LIQUIPEDIA_ID:
        renderedContact = <LiquipediaContact index={index} key={index} />;
        break;
      case TYPES.TYPE_POKEMON_GO_ID:
        renderedContact = <PokemonGoContact index={index} key={index} />;
        break;

      case TYPES.TYPE_TWITCH_NAME:
        renderedContact = <TwitchContact index={index} key={index} />;
        break;
      case TYPES.TYPE_INSTAGRAM_ID:
        renderedContact = <InstagramContact index={index} key={index} />;
        break;
      case TYPES.TYPE_TWITTER_ID:
        renderedContact = <TwitterContact index={index} key={index} />;
        break;
      case TYPES.TYPE_YOUTUBE_ID:
        renderedContact = <YoutubeContact index={index} key={index} />;
        break;
      case TYPES.TYPE_TIKTOK_ID:
        renderedContact = <TikTokContact index={index} key={index} />;
        break;
      case TYPES.TYPE_EMAIL:
        renderedContact = <EmailContact index={index} key={index} />;
        break;
      case TYPES.TYPE_WEBSITE_TAG:
        renderedContact = <WebsiteTagContact index={index} key={index} />;
        break;
      case TYPES.TYPE_SNAPCHAT_ID:
        renderedContact = <SnapchatContact index={index} key={index} />;
        break;

      case TYPES.TYPE_TWITCH_CONNECTION:
        renderedContact = <TwitchConnectionContact index={index} key={index} />;
        break;

      default:
        renderedContact = null;
    }

    const id = `${type}_${index}`;

    if (!renderedContact) return null;

    return (
      <Draggable key={id} draggableId={id} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div className="columns is-relative is-mobile">
              <div
                className="column is-narrow py-0 pl-0"
              >
                <i
                  id={styles.grabber}
                  className="fas fa-bars"
                  style={{ color: theme.text.color }}
                  {...provided.dragHandleProps}
                />
              </div>
              <div className="column p-0">
                {renderedContact}
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }), [contact, theme.text.color]);
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <>
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className=""
            >
              { renderedContacts }
            </div>
            {provided.placeholder}
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ContactEditor;
