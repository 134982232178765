import React, { useState } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import useAPI from 'hooks/useAPI';
import { useTranslation } from 'react-i18next';

// Actions
import { setProfile } from 'store/profile';

// Components
import ContactLink from 'components/contact/ContactLink';
import ContactCopy from 'components/contact/ContactCopy';

const SaveButton = (props) => {
  const { dontShowColor } = props;

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');

  const [showProfileButton, setShowProfileButton] = useState(false);

  const { t } = useTranslation(['general', 'errors']);

  const {
    currentLink: link, name, description, contact, website, theme,
  } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const { post } = useAPI();

  const isInputValid = () => {
    if (link.length === 0) {
      setStatus(t('errors:error_empty_id'));
      return false;
    }
    const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

    if (website.length !== 0 && !!pattern.test(website) === false) {
      setStatus(`${t('errors:error_enter_valid_website')}!`);
      return false;
    }

    for (let i = 0; i < contact.length; i++) {
      const { tag } = contact[i];

      if (tag.length === 0) {
        setStatus(`${t('errors:error_fill_out_all_tags')}!`);
        return false;
      }
    }

    return true;
  };

  const saveProfile = async () => {
    try {
      if (!isInputValid()) {
        return;
      }

      setStatus('');
      setLoading(true);

      const data = {
        link, name, description, contact, website, theme,
      };

      const profile = await post(/profile/, data);

      stats.push(['trackEvent', 'Profile Editor', 'Profile saved']);

      dispatch(setProfile(profile));

      setStatus(t('general:saved_successfully'));
      setLoading(false);

      setShowProfileButton(true);
    } catch (err) {
      console.log(err);
      setLoading(false);

      const error = err.response.data;
      setStatus(t(`errors:${error}`));
    }
  };

  const clickProfileLink = () => {
    stats.push(['trackEvent', 'Profile Editor', 'Profile link clicked after saving']);
  };

  const copyProfileUrl = () => {
    stats.push(['trackEvent', 'Profile Editor', 'Profile url copied after saving']);
  };

  const textColor = dontShowColor ? '#ffffff' : theme.text.color;

  const style = dontShowColor ? { color: '#ffffff' } : { color: theme.text.color, border: `2px solid ${theme.text.color}` };

  return (

    <div className="has-text-centered pt-4">
      <button
        type="button"
        className="button has-text-weight-bold has-no-background"
        onClick={saveProfile}
        disabled={loading}
        style={style}
      >
        {t('general:save')}
      </button>
      <p
        className="has-text-weight-bold mt-4"
        style={{ color: textColor }}
      >
        {status}
      </p>
      { showProfileButton
           && (
             <div className="my-5">
               <ContactLink
                 url={link}
                 onClick={clickProfileLink}
                 color={textColor}
               />
               <ContactCopy
                 text={`https://tags.gg/${link}`}
                 onClick={copyProfileUrl}
                 color={textColor}
               />
             </div>
           )}
    </div>
  );
};

export default SaveButton;
