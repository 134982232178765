import React, { useMemo } from 'react';

// Hooks
import { useSelector } from 'react-redux';

// Constants
import TYPES from 'constants/contacts';

// Components
import {
  BattleNetDisplay, DiscordDisplay, DiscordServerDisplay, RiotDisplay, SteamIDDisplay, EpicDisplay, TwitchDisplay, UbisoftDisplay, InstagramDisplay, TwitterDisplay, YoutubeDisplay, OriginDisplay, TikTokDisplay, XBoxDisplay, PSNDisplay, EmailDisplay, NintendoSwitchDisplay, JukedGGDisplay, LiquipediaDisplay, WebsiteTagDisplay, TwitchConnectionDisplay, PokemonGoDisplay, SnapchatDisplay,
} from 'components/contact/Contacts';

const ContactList = () => {
  const { contact } = useSelector((state) => state.display);

  const renderedContacts = useMemo(() => contact.map((item, index) => {
    const { type } = item;

    switch (type) {
      case TYPES.TYPE_BATTLENET_ID:
        return <BattleNetDisplay index={index} key={index} />;
      case TYPES.TYPE_STEAM_ID:
        return <SteamIDDisplay index={index} key={index} />;
      case TYPES.TYPE_RIOT_ID:
        return <RiotDisplay index={index} key={index} />;
      case TYPES.TYPE_EPIC_ID:
        return <EpicDisplay index={index} key={index} />;
      case TYPES.TYPE_DISCORD_ID:
        return <DiscordDisplay index={index} key={index} />;
      case TYPES.TYPE_DISCORD_SERVER:
        return <DiscordServerDisplay index={index} key={index} />;
      case TYPES.TYPE_UBISOFT_NAME:
        return <UbisoftDisplay index={index} key={index} />;
      case TYPES.TYPE_ORIGIN_ID:
        return <OriginDisplay index={index} key={index} />;
      case TYPES.TYPE_XBOX_ID:
        return <XBoxDisplay index={index} key={index} />;
      case TYPES.TYPE_PSN_ID:
        return <PSNDisplay index={index} key={index} />;
      case TYPES.TYPE_NINTENDO_SWITCH_ID:
        return <NintendoSwitchDisplay index={index} key={index} />;
      case TYPES.TYPE_JUKEDGG_ID:
        return <JukedGGDisplay index={index} key={index} />;
      case TYPES.TYPE_LIQUIPEDIA_ID:
        return <LiquipediaDisplay index={index} key={index} />;
      case TYPES.TYPE_POKEMON_GO_ID:
        return <PokemonGoDisplay index={index} key={index} />;

      case TYPES.TYPE_TWITCH_NAME:
        return <TwitchDisplay index={index} key={index} />;
      case TYPES.TYPE_INSTAGRAM_ID:
        return <InstagramDisplay index={index} key={index} />;
      case TYPES.TYPE_TWITTER_ID:
        return <TwitterDisplay index={index} key={index} />;
      case TYPES.TYPE_YOUTUBE_ID:
        return <YoutubeDisplay index={index} key={index} />;
      case TYPES.TYPE_TIKTOK_ID:
        return <TikTokDisplay index={index} key={index} />;
      case TYPES.TYPE_EMAIL:
        return <EmailDisplay index={index} key={index} />;
      case TYPES.TYPE_WEBSITE_TAG:
        return <WebsiteTagDisplay index={index} key={index} />;
      case TYPES.TYPE_SNAPCHAT_ID:
        return <SnapchatDisplay index={index} key={index} />;

      case TYPES.TYPE_TWITCH_CONNECTION:
        return <TwitchConnectionDisplay index={index} key={index} />;

      default:
        return null;
    }
  }), [contact]);
  return renderedContacts;
};

export default ContactList;
