import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { TYPE_BATTLENET_ID } from 'constants/contacts';

// Assets
import img from 'assets/images/icons/battle_net_icon.png';

// Components
import ContactField from '../../ContactField';
import ContactButton from '../../ContactButton';
import ContactDisplay from '../../ContactDisplay';

const BattleNetContact = (props) => {
  const { index } = props;

  const { t } = useTranslation(['errors']);

  const validate = (tag) => {
    let error = '';
    if (tag.length > 18) {
      error = t('errors:error_enter_valid_tag');
    }

    return { error, tag };
  };

  return (
    <div>
      <ContactField
        icon={<i className="fab fa-battle-net is-size-2 has-text-info" />}
        index={index}
        validate={validate}
        label="Battle.net ID"
        placeholder="Name#1337"
      />
    </div>
  );
};

BattleNetContact.propTypes = {
  index: PropTypes.number.isRequired,
};

export const BattleNetDisplay = (props) => {
  const { index } = props;
  /*
  const { contact } = useSelector((state) => state.profile);
  const { tag } = contact[index];
  const [name, number] = tag.split('#');
  <div className="has-border-white-light">
    <a
      href={`https://playoverwatch.com/en-us/career/pc/${name}-${number}`}
      target="_blank"
      rel="noreferrer"
      className="has-text-white has-text-weight-bold is-block"
    >
      Overwatch
    </a>
  </div>
  */

  return (
    <div>
      <ContactDisplay
        icon={<i className="fab fa-battle-net is-size-2 has-text-info" />}
        index={index}
        link="https://battle.net"
        label="Battle.net ID"
      />
    </div>
  );
};

BattleNetDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export const AddBattleNetContact = () => <ContactButton label="Battle.net" type={TYPE_BATTLENET_ID} img={img} />;

export default BattleNetContact;
