import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Constants
import { TYPE_EMAIL } from 'constants/contacts';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Assets
import img from 'assets/images/icons/email_icon.png';
import img_display from 'assets/images/icons/email_icon_display.png';

// Components
import ContactField from '../../ContactField';
import ContactButton from '../../ContactButton';
import ContactDisplay from '../../ContactDisplay';
import ContactLink from '../../ContactLink';
import ContactCopy from '../../ContactCopy';

const EmailContact = (props) => {
  const { index } = props;

  const { t } = useTranslation(['contact', 'errors']);

  const validate = (tag) => {
    let error = '';
    if (tag.length > 256) {
      error = t('errors:error_enter_valid_tag');
    }

    return { error, tag };
  };

  return (
    <>
      <ContactField
        icon={<img src={img_display} alt="" className="has-width-40" />}
        index={index}
        validate={validate}
        label="E-Mail"
        placeholder="your@email.com"
      />

    </>
  );
};

EmailContact.propTypes = {
  index: PropTypes.number.isRequired,
};

export const EmailDisplay = (props) => {
  const { contact, theme } = useSelector((state) => state.display);
  const { index } = props;

  const { tag } = contact[index];

  return (
    <div>
      <ContactDisplay
        icon={<img src={img_display} alt="" className="has-width-40" />}
        index={index}
        label="E-Mail"
        link={`mailto:${tag}`}
      >
        <ContactLink url={`mailto:${tag}`} color={theme.text.color} />
        <ContactCopy text={tag} color={theme.text.color} />
      </ContactDisplay>
    </div>
  );
};

EmailDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export const AddEmailContact = () => (
  <ContactButton
    type={TYPE_EMAIL}
    img={img}
    label="E-Mail"
  />
);

export default EmailContact;
