import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_PROFILE } from './profile';

const initialState = {
  hasData: false,
  isLoading: true,

  ...INITIAL_PROFILE,
  connections: null,
};

export const displaySlice = createSlice({
  name: 'profile',
  initialState,
  extraReducers: (builder) => {
    // clear creator on CLEAR_STATE
    builder.addCase('CLEAR_STATE', (state) => {
      state = initialState;
      return state;
    });
  },
  reducers: {
    setProfileDisplay: (state, action) => ({
      ...INITIAL_PROFILE,
      hasData: true,
      isLoading: false,
      ...action.payload,
    }),
    clearProfileDisplay: () => initialState,
  },
});

export const {
  setProfileDisplay, clearProfile,
} = displaySlice.actions;

export default displaySlice.reducer;
