import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Components
import { SketchPicker } from 'react-color';

// Styles
import styles from './styles.module.scss';

// ['#292841', '#7c0404', '#120981', '#0a6828', '#492207', '#500880', '#113a53']

const ColorPicker = (props) => {
  const {
    color, onChange, onChangeComplete, presetColors,
  } = props;

  return (
    <SketchPicker
      color={color}
      onChange={onChange}
      onChangeComplete={onChangeComplete}
      presetColors={presetColors}
      className={styles.picker}
      disableAlpha
    />
  );
};

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeComplete: PropTypes.func,
  presetColors: PropTypes.arrayOf(PropTypes.string),
};

ColorPicker.defaultProps = {
  onChangeComplete: () => {},
  presetColors: undefined,
};

export default ColorPicker;
