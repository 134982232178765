import React, { useState } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Actions
import { setWebsite } from 'store/profile';

const WebsiteEditor = () => {
  const { t } = useTranslation(['general', 'errors']);

  const { website, theme } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const [error, setError] = useState('');

  const _setWebsite = (_website) => {
    setError('');

    dispatch(setWebsite(_website));
  };

  return (
    <div className="field my-3" style={{ color: theme.text.color }}>
      <div className="control">
        <div className="">
          <p>
            {t('general:website')}
          </p>
          <input
            onChange={(event) => _setWebsite(event.target.value)}
            type="text"
            value={website}
            placeholder={t('general:website')}
            className="input is-inline-block has-no-background"
            style={{ color: theme.text.color, border: `1px solid ${theme.text.color}` }}
          />
        </div>
        <p className="has-text-centered mt-2" style={{ color: theme.text.color }}>
          { error }
        </p>
      </div>
    </div>
  );
};

export default WebsiteEditor;
