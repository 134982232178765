import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { TYPE_JUKEDGG_ID } from 'constants/contacts';

// Assets
import img from 'assets/images/icons/jukedgg.png';

// Components
import ContactField from '../../ContactField';
import ContactButton from '../../ContactButton';
import ContactDisplay from '../../ContactDisplay';

const JukedGGContact = (props) => {
  const { index } = props;

  const { t } = useTranslation(['errors']);

  const validate = (tag) => {
    let error = '';
    if (tag.length > 256) {
      error = t('errors:error_enter_valid_tag');
    }

    return { error, tag };
  };

  return (
    <ContactField
      icon={<img src={img} alt="" className="has-width-40" />}
      index={index}
      validate={validate}
      label="Juked.gg"
      placeholder="Name"
    />
  );
};

JukedGGContact.propTypes = {
  index: PropTypes.number.isRequired,
};

export const JukedGGDisplay = (props) => {
  const { index } = props;

  return (
    <ContactDisplay
      icon={<img src={img} alt="" className="has-width-40" />}
      index={index}
      link="https://app.juked.gg"
      label="Juked.gg"
    />
  );
};

JukedGGDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export const AddJukedGGContact = () => <ContactButton label="Juked.gg" type={TYPE_JUKEDGG_ID} img={img} />;

export default JukedGGContact;
