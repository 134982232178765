import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Constants
import { TYPE_LIQUIPEDIA_ID } from 'constants/contacts';

// Assets
import img from 'assets/images/icons/liquipedia_icon.png';
import img_display from 'assets/images/icons/liquipedia_icon_display.png';

// Components
import ContactField from '../../ContactField';
import ContactButton from '../../ContactButton';
import ContactDisplay from '../../ContactDisplay';
import ContactLink from '../../ContactLink';
import ContactCopy from '../../ContactCopy';

const LiquipediaContact = (props) => {
  const { index } = props;

  const { t } = useTranslation(['errors']);

  const validate = (tag) => {
    let error = '';
    if (tag.length > 256) {
      error = t('errors:error_enter_valid_tag');
    }

    if (tag.startsWith('https://liquipedia.net/') || tag.startsWith('https://www.liquipedia.net/')) {
      tag = tag.split('liquipedia.net/')[1] || '';
    }
    return { error, tag };
  };

  return (
    <ContactField
      icon={<img src={img_display} alt="" className="has-width-40" />}
      index={index}
      validate={validate}
      label="Liquipedia"
      placeholder="counterstrike/S1mple"
      hasName
      namePlaceholder="Liquipedia name (optional)"
    />
  );
};

LiquipediaContact.propTypes = {
  index: PropTypes.number.isRequired,
};

export const LiquipediaDisplay = (props) => {
  const { contact, theme } = useSelector((state) => state.display);
  const { index } = props;

  const { tag } = contact[index];

  return (
    <ContactDisplay
      icon={<img src={img_display} alt="" className="has-width-40" />}
      index={index}
      link={`https://liquipedia.net/${tag}`}
      label="Liquipedia"
    >
      <ContactLink url={`https://liquipedia.net/${tag}`} color={theme.text.color} />
      <ContactCopy text={`https://liquipedia.net/${tag}`} color={theme.text.color} />
    </ContactDisplay>
  );
};

LiquipediaDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export const AddLiquipediaContact = () => <ContactButton label="Liquipedia" type={TYPE_LIQUIPEDIA_ID} img={img} />;

export default LiquipediaContact;
