import React from 'react';

const GermanTOS = () => (
  <div>
    <h1 className="has-text-weight-bold is-size-5 mb-3">
      Teilnahmebedingungen Giveaways / Gewinnspiele
    </h1>
    <p><strong><span>1. Gegenstand der Teilnahmebedingungen und Veranstalter</span></strong></p>

    <p>
      <span>
        (1)&nbsp;&nbsp;&nbsp;&nbsp; Diese Teilnahmebedingungen regeln die Bedingungen für eine Teilnahme an Giveaway Aktionen sowie Gewinnspielen (nachfolgend
        <strong>„Gewinnspiel“</strong>
        ). Die nähere Beschreibung und der Ablauf des jeweiligen Giveaways oder&nbsp; Gewinnspiels erfolgen im Rahmen der jeweiligen Gewinnspielaktion auf den Social Media Kanälen der Flowdust Online Services UG (haftungsbeschränkt) bzw. über die Plattform Gleam.io.
      </span>
    </p>

    <p><span>(2)&nbsp;&nbsp;&nbsp;&nbsp; Veranstalter des Gewinnspiels ist die Flowdust Online Services UG (haftungsbeschränkt), Am Bahnhof 1, 95632 Wunsiedel.</span></p>

    <p><span>(3)&nbsp;&nbsp;&nbsp;&nbsp; Mit Teilnahme an dem jeweiligen Gewinnspiel werden diese Teilnahmebedingungen angenommen.</span></p>

    <p>
      <span>(4)&nbsp;&nbsp;&nbsp;&nbsp; Das Gewinnspiel steht in keiner Verbindung zu den Betreibern der genutzten Social Media Plattformen, es wird weder von ihnen gesponsert, unterstützt noch organisiert.</span>
      <span>&nbsp;</span>
    </p>

    <p>&nbsp;</p>

    <p><strong><span>2. Teilnahme</span></strong></p>

    <p><span>(1)&nbsp;&nbsp;&nbsp;&nbsp; Teilnahmeberechtigt am Gewinnspiel sind alle, die das 18. Lebensjahr vollendet haben. Lokale Einschränkungen bezüglich der Teilnahme können sich aus den jeweiligen Gewinnspiel-Postings auf Social Media ergeben. &nbsp;Ausgeschlossen sind Mitarbeiter der Flowdust Online Services UG (haftungsbeschränkt) sowie verbundener Unternehmen sowie Angehörige solcher Mitarbeiter und deren Verwandte. Die Teilnahme mit gefälschten Identitäten oder mit Identitäten von Dritten ist nicht erlaubt.</span></p>

    <p><span>(2)&nbsp;&nbsp;&nbsp;&nbsp; Teilnahmeberechtigte können an Gewinnspielen teilnehmen, indem sie die jeweiligen auf Social Media angegebenen Handlungen zur Teilnahme durchführen, z.B. das&nbsp; Gewinnspiel-Posting liken („Gefällt-mir“ klicken) und diese Bewertung jedenfalls bis zur Auswahl der Gewinner belassen. Weitere Teilnahmebedingungen können sich bei Nutzung der Plattform Gleam.io ergeben und werden dem Nutzer dort angezeigt.</span></p>

    <p>&nbsp;</p>

    <p>
      <strong>
        <span>3. Gewinne</span>
        <span> und Gewinnbenachrichtigung</span>
      </strong>
    </p>

    <p><span>(1)&nbsp;&nbsp;&nbsp;&nbsp; Unter allen teilnahmeberechtigten Teilnehmern verlosen wir die im jeweiligen Gewinnspielposting auf Social Media angeführten Preise.&nbsp; </span></p>

    <p><span>(2)&nbsp;&nbsp;&nbsp;&nbsp; Die Gewinner werden innerhalb einer Woche nach Teilnahmeschluss unter allen teilnahmeberechtigten Teilnehmern per Zufallsprinzip ermittelt. Die Gewinner werden per Kommentar-Funktion oder in einem gesonderten Posting benachrichtigt und aufgefordert, eine Social Media Direktnachricht an das gewinnspielveranstaltende Social Media Profil oder eine E-Mail an eine dort angegebene E-Mail-Adresse zu senden, in der die persönlichen Daten ordnungs- und wahrheitsgemäß vollständig übermittelt werden. Diese Angaben dienen der Übersendung des Gewinns. Bei Gewinnspielteinahme über die Plattform Gleam.io kann eine Benachrichtigung zudem durch eine Email Benachrichtigung von Gleam.io erfolgen.</span></p>

    <p><span>(3)&nbsp;&nbsp;&nbsp;&nbsp; Geht von einem Gewinner innerhalb einer Frist von 14 Tagen ab Zugang der Gewinnbenachrichtigung keine Social Media Direktnachricht oder E-Mail bei uns ein, erlischt der Gewinnanspruch. Wir sind in diesem Fall berechtigt, eine Ersatzauslosung durchzuführen.</span></p>

    <p><span>(4)&nbsp;&nbsp;&nbsp;&nbsp; Je Teilnehmer ist pro Gewinnspiel nur ein Gewinn möglich.</span></p>

    <p><span>(5)&nbsp;&nbsp;&nbsp;&nbsp; Der Gewinn ist weder übertragbar, noch kann der Gewinn getauscht oder in bar ausgezahlt werden.</span></p>

    <p><span>(6)&nbsp;&nbsp;&nbsp;&nbsp; Sofern Umstände eintreten, die wir nicht zu vertreten haben, akzeptiert der jeweilige Gewinner einen angemessenen Ersatzgewinn. Solche nicht zu vertretenden Umstände sind insbesondere solche, die bei den Sponsoren der Gewinne liegen.</span></p>

    <p><span>(7)&nbsp;&nbsp;&nbsp;&nbsp; Die Gewinne werden dem Gewinner per Email oder Social Media Direktnachricht übermittelt. Bei körperlichen Gegenständen wird der Gewinn per Post an die angegebene Anschrift gesendet. Mit Übergabe des Gewinns an eine Transportperson geht die Gefahr auf den Gewinner über. Für Lieferschäden sind wir nicht verantwortlich.</span></p>

    <p><span>&nbsp;</span></p>

    <p><strong><span>4. Nutzungsrechteeinräumung,&nbsp; Haftung und Freistellung</span></strong></p>

    <p><span>(1)&nbsp;&nbsp;&nbsp;&nbsp; Mit der Annahme des Gewinns willigt der Gewinner ein, dass wir seinen Namen zu Werbezwecken verwenden dürfen.</span></p>

    <p><span>(2)&nbsp;&nbsp;&nbsp;&nbsp; Der Teilnehmer stellt uns von Ansprüchen Dritter gleich welcher Art frei, die aus der Rechtswidrigkeit der vom Teilnehmer&nbsp; ggf. geposteten Inhalte resultieren, die der Teilnehmer verwendet hat. Die Freistellungsverpflichtung umfasst auch die Verpflichtung, den Veranstalter von Rechtsverteidigungskosten (z.B. Gerichts- und Anwaltskosten) vollständig freizustellen.</span></p>

    <p><span>(3)&nbsp;&nbsp;&nbsp;&nbsp; Mit der Teilnahme am Gewinnspiel stellt der Teilnehmer die genutzte(n) Social Media Plattform(en) von jeder Haftung frei.</span></p>

    <p>&nbsp;</p>

    <p><strong><span>5. Ausschluss</span></strong></p>

    <p>
      <span>(1)&nbsp;&nbsp;&nbsp;&nbsp; Ein Verstoß gegen diese Teilnahmebedingungen berechtigt uns, den jeweiligen Teilnehmer von der Teilnahme auszuschließen. &nbsp;Dies gilt insbesondere, wenn der Teilnehmer falsche Angaben macht oder gepostete Inhalte (z.B. Kommentare) geltendes Recht oder Rechte Dritter verletzen. Gleiches gilt bei Kommentaren, die als gewaltverherrlichend, anstößig, belästigend oder herabwürdigend angesehen werden können oder in sonstiger Weise gegen das gesellschaftliche Anstandsgefühl verstoßen. </span>
      <span>&nbsp;</span>
      <span>Falls Bots oder Like-Betrug für die Bewertung der eigenen Kommentare, Posts, oder Likes&nbsp; eingesetzt werden, kann der Teilnehmer ebenfalls vom Gewinnspiel ausgeschlossen werden. Gleiches gilt, wenn ein Teilnehmer mehr als einen Kommentar unter einen Gewinnspielbeitrag postet. Eine Mehrfachkommentierungen steigern insoweit nicht die Gewinnchance, sondern können zum Ausschluss führen.</span>
    </p>

    <p><span>(2)&nbsp;&nbsp;&nbsp;&nbsp; Handelt es sich bei dem ausgeschlossenen Teilnehmer um einen bereits ausgelosten Gewinner, kann der Gewinn nachträglich aberkannt werden.</span></p>

    <p><span>&nbsp;</span></p>

    <p><strong><span>6. Vorzeitige Beendigung sowie Änderungen</span></strong></p>

    <p><span>Wir behalten uns das Recht vor, das Gewinnspiel jederzeit, auch ohne Einhaltung von Fristen, ganz oder teilweise vorzeitig zu beenden oder in seinem Verlauf abzuändern, wenn es aus technischen (z.B. Computervirus, Manipulation von oder Fehler in Software/Hardware) oder rechtlichen Gründen (z.B. Untersagung durch Facebook) nicht möglich ist, eine ordnungsgemäße Durchführung des Gewinnspiels zu garantieren.</span></p>

    <p><span>&nbsp;</span></p>

    <p><strong><span>7. Datenschutz</span></strong></p>

    <p>
      <span>Wir sind verantwortlich für die Erhebung, Verarbeitung und Nutzung von personenbezogenen Daten der Teilnehmer, sofern wir diese selbst verarbeiten. Wir werden die Angaben zur Person des Teilnehmers sowie seine sonstigen personenbezogenen Daten nur im Rahmen der gesetzlichen Bestimmungen des Datenschutzrechtes (DSGVO und BDSG neu) verwenden. Wir werden die Informationen nur insoweit speichern, verarbeiten und nutzen, soweit dies für die Durchführung des Gewinnspiels erforderlich ist bzw. eine Einwilligung des Teilnehmers vorliegt. Dies umfasst auch eine Verwendung zur Ausübung der eingeräumten Nutzungsrechte. </span>
      <span>Die Daten werden ausschließlich zur Durchführung des Gewinnspiels verwendet und anschließend gelöscht. </span>
      <span>
        Der Teilnehmer kann jederzeit Auskunft über die zu seiner Person gespeicherten Daten verlangen. Im Übrigen gilt unsere Datenschutzerklärung entsprechend, die
        <ins><a href="https://tags.gg/privacy" className="ml-1">hier</a></ins>
      </span>
      <span>&nbsp;abrufbar ist.</span>
    </p>

    <p>
      <span>&nbsp;</span>
      <span>&nbsp;</span>
    </p>

    <p><strong><span>8. Schlussbestimmungen</span></strong></p>

    <p><span>(1)&nbsp;&nbsp;&nbsp;&nbsp; Sollten die Teilnahmebedingungen unwirksame Regelungen enthalten, bleibt die Wirksamkeit der Bedingungen im Übrigen unberührt.</span></p>

    <p><span>(2)&nbsp;&nbsp;&nbsp;&nbsp; Es gilt deutsches Recht. Ein Rechtsweg zur Überprüfung der Auslosung ist ausgeschlossen.</span></p>

    <p><span>&nbsp;</span></p>
  </div>
);

export default GermanTOS;
