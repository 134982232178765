import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

// Components
import Popup from 'reactjs-popup';

// Hooks
import { useSelector } from 'react-redux';

// Components
import ProfilePicture from 'components/profile/ProfilePicture';
import PictureUploader from './PictureUploader';
import ProfilePictureDemoPopup from './ProfilePictureDemoPopup';

/**
 * Drag & Drop Zone to upload images
 */
const ProfilePictureEditor = () => {
  const { t } = useTranslation(['general', 'errors']);

  const { profilePic, contact, theme } = useSelector((state) => state.profile);

  const { isAuthenticated } = useAuth0();

  return (
    <div
      className="control is-flex flex-direction-column has-content-centered"
    >
      <ProfilePicture img={profilePic} contact={contact} color={theme.text.color} />
      <Popup
        trigger={(
          <button
            type="button"
            className="cleanButton has-text-weight-bold mt-4"
            style={{ color: theme.text.color }}
          >
            {t('general:change_profile_picture')}
          </button>
        )}
        position="top center"
        on={['click']}
        keepTooltipInside="#root"
        repositionOnResize
        modal
        className=""
      >
        {
          (close) => (isAuthenticated
            ? <PictureUploader close={close} />
            : <ProfilePictureDemoPopup />
          )
        }
      </Popup>
    </div>
  );
};

export default ProfilePictureEditor;
