import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';
import { sanitizeUrl } from '@braintree/sanitize-url';

const ContactLink = (props) => {
  const { url, onClick, color } = props;

  const click = () => {
    // we dont need to specify where to go because matomo will show us anyway
    stats.push(['trackEvent', 'Contact Link', 'clicked']);

    onClick();
  };

  const sanitizedUrl = useMemo(() => sanitizeUrl(url), [url]);

  return (
    <a
      target="_blank"
      rel="noreferrer"
      className="button has-no-background has-fullheight px-3 py-2 br-2 has-hover is-inline-block"
      href={sanitizedUrl}
      onClick={click}
      style={{ color, border: `1px solid ${color}` }}
    >
      <i className="fas fa-external-link-alt" />
    </a>
  );
};

ContactLink.propTypes = {
  url: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,

};

ContactLink.defaultProps = {
  onClick: () => {},
};

export default ContactLink;
