import React, { useMemo } from 'react';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Constants
import { TYPE_TWITCH_CONNECTION } from 'constants/contacts';

// Components
import {
  AddDiscordContact,
  AddDiscordServerContact,
  AddSteamIDContact,
  AddRiotContact,
  AddBattleNetContact,
  AddEpicContact,
  AddTwitchContact,
  AddUbisoftContact,
  AddTwitterContact,
  AddInstagramContact,
  AddYoutubeContact,
  AddOriginContact,
  AddTikTokContact,
  AddXBoxContact,
  AddPSNContact,
  AddEmailContact,
  AddNintendoSwitchContact,
  AddJukedGGContact,
  AddLiquipediaContact,
  AddWebsiteTagContact,
  AddTwitchConnectionContact,
  AddPokemonGoContact,
  AddSnapchatContact,
} from 'components/contact/Contacts';

// Styles
import styles from './styles.module.scss';

const GameSidebar = () => {
  const { t } = useTranslation(['general']);
  const { connections, contact } = useSelector((state) => state.profile);

  const hasTwitchConnectionTag = useMemo(() => {
    const index = contact.findIndex((_contact) => _contact.type === TYPE_TWITCH_CONNECTION);

    return index !== -1;
  }, [contact]);

  return (
    <div id={styles.wrapper} className="is-fullheight has-background-primary has-text-centered">
      {
      connections.twitch.isConnected && !hasTwitchConnectionTag
      && (
        <div className="is-flex-mobile ">
          <div className="has-text-centered is-flex align-items-center px-3">
            <p className="is-size-7 has-text-weight-bold">
              {t('general:connections')}
            </p>
          </div>
          <AddTwitchConnectionContact />
        </div>
      )
    }

      <div className="has-text-centered px-3">
        <p className="is-size-7 has-text-weight-bold">
          {t('general:gamer_tags')}
        </p>
      </div>

      <AddDiscordContact />
      <AddDiscordServerContact />
      <AddSteamIDContact />
      <AddRiotContact />
      <AddBattleNetContact />
      <AddEpicContact />
      <AddTwitchContact />
      <AddUbisoftContact />
      <AddOriginContact />
      <AddXBoxContact />
      <AddPSNContact />
      <AddNintendoSwitchContact />
      <AddJukedGGContact />
      <AddLiquipediaContact />
      <AddPokemonGoContact />

      <div className="has-text-centered px-3">
        <p className="is-size-7 has-text-weight-semibold">
          {t('general:socials')}
        </p>
      </div>

      <AddEmailContact />
      <AddTwitterContact />
      <AddInstagramContact />
      <AddYoutubeContact />
      <AddTikTokContact />
      <AddSnapchatContact />
      <AddWebsiteTagContact />
    </div>
  );
};

export default GameSidebar;
