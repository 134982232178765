import React from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

// Actions
import { setShowThemeEditor } from 'store/profile';

// Components
import ContactLink from 'components/contact/ContactLink';
import ContactCopy from 'components/contact/ContactCopy';
import ProfilePictureEditor from './ProfilePictureEditor';
import WebsiteEditor from './WebsiteEditor';
import LinkEditor from './LinkEditor';
import NameEditor from './NameEditor';
import DescEditor from './DescEditor';
import ContactEditor from './ContactEditor';
import SaveButton from '../SaveButton';
import SaveButtonDemoPopup from './SaveButtonDemoPopup';

// Styles
import styles from './styles.module.scss';

const ProfileCard = () => {
  const {
    theme, link, showThemeEditor,
  } = useSelector((state) => state.profile);

  const { isAuthenticated } = useAuth0();
  const dispatch = useDispatch();

  const clickProfileLink = () => {
    stats.push(['trackEvent', 'Profile Editor', 'Profile link clicked']);
  };

  const copyProfileUrl = () => {
    stats.push(['trackEvent', 'Profile Editor', 'Profile url copied']);
  };

  const toggleThemeEditor = () => {
    dispatch(setShowThemeEditor(!showThemeEditor));
  };

  return (
    <div
      id={styles.wrapper}
      className="boxshadow has-margin-auto has-text-white box br-5 my-6"
      style={{ backgroundColor: theme.profile.values[0] }}
    >
      <div className="columns">
        {
          isAuthenticated && link
          && (
          <div className="column is-6">
            <ContactLink
              url={link}
              onClick={clickProfileLink}
              color={theme.text.color}
            />
            <ContactCopy
              text={`https://tags.gg/${link}`}
              onClick={copyProfileUrl}
              color={theme.text.color}
            />
          </div>
          )
        }
        <div className="column has-text-right">
          <button
            className="button has-no-background has-fullheight px-3 py-2 br-2 has-hover is-inline-block"
            style={{ color: theme.text.color, border: `1px solid ${theme.text.color}` }}
            type="button"
            onClick={toggleThemeEditor}
          >
            <i className="is-size-5 fas fa-cog" />
          </button>

        </div>
      </div>
      <div>
        <ProfilePictureEditor />
      </div>
      <div>
        <LinkEditor />
      </div>
      <div>
        <NameEditor />
      </div>
      <div>
        <DescEditor />
      </div>
      <div>
        <WebsiteEditor />
      </div>
      <div>
        <ContactEditor />
      </div>
      <div>
        {
          isAuthenticated ? <SaveButton /> : <SaveButtonDemoPopup />
        }
      </div>
    </div>
  );
};

export default ProfileCard;
