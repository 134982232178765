import React from 'react';
import { Helmet } from 'react-helmet-async';

// Hooks
import { useTranslation } from 'react-i18next';

const Imprint = () => {
  const { t } = useTranslation('general');
  return (
    <>
      <Helmet>
        <title>Tags.gg - Impressum</title>
      </Helmet>
      <div className="is-fullheight">
        <div className="columns has-fullheight is-vcentered is-marginless is-centered is-mobile">
          <div className="column is-8-desktop is-10-tablet is-11-mobile br-20 px-4 py-4">
            <h1 className="mt-6 mb-5 has-text-centered has-text-white has-text-weight-bold is-size-4">
              {t('general:imprint')}
            </h1>
            <div className="columns is-centered">
              <div className="has-background-primary br-10 boxshadow is-5 column  has-text-centered p-6">
                <p className="has-text-weight-bold mb10">Flowdust Online Services UG (haftungsbeschränkt)</p>
                <p className="has-text-weight-light">Am Bahnhof 1</p>
                <p className="has-text-weight-light">95632 Wunsiedel</p>
                <p className="has-text-weight-light">Deutschland</p>
                <p className="has-text-weight-bold mt30 mb10">Geschäftsführer:</p>
                <p className="has-text-weight-light">Kevin Pollak</p>
                <p className="has-text-weight-light">Ariane Pollak</p>
                <p className="has-text-weight-light">(Anschrift wie oben)</p>
                <p className="has-text-weight-bold mt30 mb10">Kontakt:</p>
                <p className="has-text-weight-light">E-Mail: hello@flowdust.com</p>
                <p className="has-text-weight-bold mt30 mb10">Handelsregister:</p>
                <p className="has-text-weight-light">Registergericht: Amtsgericht Hof</p>
                <p className="has-text-weight-light">Registernummer: HRB 6045</p>
                <p className="has-text-weight-bold mt30 mb10">Umsatzsteuer:</p>
                <p className="has-text-weight-light">Umsatzsteuer-ID: DE325502448</p>
                <p className="has-text-weight-light">Steuernummer: 223/126/50596</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Imprint;
