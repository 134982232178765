import React, { useState } from 'react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Actions
import { setCurrentLink } from 'store/profile';

const LinkEditor = () => {
  const { t } = useTranslation(['errors']);

  const { currentLink: link, theme } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const [error, setError] = useState('');

  const _setLink = (_link) => {
    setError('');
    // blacklist characters
    const regex = /[/?,^<>"'\\]/;

    // allow clearing the input field and check for letters
    if (_link.length !== 0 && regex.test(_link)) {
      setError(t('errors:error_enter_valid_id'));
      return;
    }

    dispatch(setCurrentLink(_link));
  };

  return (
    <div className="field mt-3" style={{ color: theme.text.color }}>
      <div className="control">
        <p>
          ID
        </p>
        <div className="br4" style={{ border: `1px solid ${theme.text.color}` }}>
          <div className="columns is-mobile">
            <div className="column is-narrow p-0">
              <div
                className="is-flex has-fullheight has-content-centered px-2 has-text-white has-background-grey"
                style={{ borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}
              >
                <p
                  className="has-text-weight-bold"
                >
                  tags.gg/
                </p>
              </div>
            </div>
            <div className="column p-0 pl-2">
              <input
                onChange={(event) => _setLink(event.target.value)}
                type="text"
                value={link}
                placeholder={t('general:id')}
                className="input is-size-5 has-text-weight-bold has-no-background has-no-border p-0 is-size-6"
                style={{ color: theme.text.color }}
              />
            </div>
          </div>
        </div>
        <p className="has-text-centered mt-2" style={{ color: theme.text.color }}>
          { error }
        </p>
      </div>
    </div>
  );
};

export default LinkEditor;
