import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setContact, setContactName, removeContact } from 'store/profile';

// Constants
import { MAX_CONTACT_NAME_LENGTH } from 'constants/contacts';

// Assets
import verifiedBadge from 'assets/images/icons/verified.png';

const ContactField = (props) => {
  const {
    icon, validate, label, placeholder, index, disabled, hasName, namePlaceholder, isVerified, children,
  } = props;

  const { contact, theme } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const [error, setError] = useState('');

  const setAndValidate = async (input) => {
    setError('');

    const result = await validate(input);

    const { error: err, tag } = result;

    if (err) {
      setError(err);
      return;
    }

    dispatch(setContact({ id: index, tag }));
  };

  const setAndValidateName = async (input) => {
    if (input.length > MAX_CONTACT_NAME_LENGTH) return;

    dispatch(setContactName({ id: index, name: input }));
  };

  const deleteItem = () => {
    stats.push(['trackEvent', 'Contact Field', `Tag ${label} deleted`]);
    dispatch(removeContact(index));
  };

  if (!contact[index]) return null;

  const { tag, name } = contact[index];

  return (
    <div className="field">
      <label className="label mb-0" style={{ color: theme.text.color }}>
        {label}
        { isVerified
                && <img src={verifiedBadge} className="has-width-16 has-height-16 ml-1" alt="" />}
      </label>
      <div className="control">
        <div className="columns is-vcentered is-mobile">
          <div className="column is-narrow p-0 has-fullheight is-flex has-content-centered">
            { icon }
          </div>
          <div className="column">
            { children || (
              <>
                {
              hasName
              && (
              <div className="mb-2">
                <input
                  className="input is-inline-block has-no-background has-text-weight-bold"
                  type="text"
                  value={name}
                  placeholder={namePlaceholder}
                  disabled={disabled}
                  onChange={(e) => setAndValidateName(e.target.value)}
                  style={{ border: `1px solid ${theme.text.color}`, color: theme.text.color }}
                />
              </div>
              )
            }
                <div>
                  <input
                    className={`input is-inline-block has-no-background ${!hasName ? 'has-text-weight-bold' : ''}`}
                    type="text"
                    value={tag}
                    placeholder={placeholder}
                    disabled={disabled}
                    onChange={(e) => setAndValidate(e.target.value)}
                    style={{ border: `1px solid ${theme.text.color}`, color: theme.text.color }}
                  />
                </div>
              </>
            ) }

          </div>
          <div className="column is-narrow p-0">
            <button
              type="button"
              onClick={deleteItem}
              className="cleanButton has-text-danger px-0"
            >
              <i className="fas fa-times" />
            </button>
          </div>
        </div>
      </div>
      {
        error && (
          <div className="has-text-centered mb-3" style={{ color: theme.text.color }}>
            <p className="has-text-weight-semibold">
              {`${error}!`}
            </p>
          </div>
        )
      }
    </div>
  );
};

ContactField.propTypes = {
  icon: PropTypes.shape({}).isRequired,
  validate: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]).isRequired,
  index: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  hasName: PropTypes.bool,
  namePlaceholder: PropTypes.string,
  isVerified: PropTypes.bool,
  children: PropTypes.node,
};

ContactField.defaultProps = {
  placeholder: 'Tag',
  disabled: false,
  hasName: false,
  namePlaceholder: 'Name',
  isVerified: false,
  children: null,
};

export default ContactField;
