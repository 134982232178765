import React, { useState } from 'react';

// Constants
import { MAX_DESC_LENGTH } from 'constants/contacts';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Actions
import { setDescription } from 'store/profile';

const DescEditor = () => {
  const { description, theme } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const { t } = useTranslation(['general']);

  const [error, setError] = useState('');

  const _setDescription = (desc) => {
    setError('');

    // check max length
    if (desc.length >= MAX_DESC_LENGTH) {
      setError('Maximale Länge erreicht!');
      return;
    }

    dispatch(setDescription(desc));
  };

  return (
    <>
      <div className="field my-3" style={{ color: theme.text.color }}>
        <p>
          { t('general:description') }
        </p>
        <div className="control">
          <textarea
            onChange={(event) => _setDescription(event.target.value)}
            type="text"
            value={description}
            rows={2}
            placeholder={t('general:description')}
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            className="textarea br4 is-size-6 no-shadow p-3 has-fixed-size is-paddingless is-inline has-fullheight has-no-background"
            style={{ color: theme.text.color, border: `1px solid ${theme.text.color}` }}
          />
          {
            error
            && (
            <p
              className="has-text-weight-bold has-text-centered mt-4"
              style={{ color: theme.text.color }}
            >
              { error }
            </p>
            )
          }
        </div>
      </div>
    </>
  );
};

export default DescEditor;
