import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Constants
import { TYPE_INSTAGRAM_ID } from 'constants/contacts';

// Assets
import img from 'assets/images/icons/instagram_icon.png';
import img_display from 'assets/images/icons/instagram_icon_display.png';

// Components
import ContactField from '../../ContactField';
import ContactButton from '../../ContactButton';
import ContactDisplay from '../../ContactDisplay';
import ContactLink from '../../ContactLink';

const InstagramContact = (props) => {
  const { index } = props;

  const { t } = useTranslation(['general']);

  const validate = (tag) => {
    let error = '';
    if (tag.length > 256) {
      error = t('general:error_enter_valid_tag');
    }

    if (tag.includes('instagram.com/')) {
      tag = tag.split('instagram.com/')[1] || '';
      tag = tag.replace('/', '');
    }

    return { error, tag };
  };

  return (
    <ContactField
      icon={<img src={img_display} alt="" className="has-width-40" />}
      index={index}
      validate={validate}
      label="Instagram"
      placeholder="Name"
    />
  );
};

InstagramContact.propTypes = {
  index: PropTypes.number.isRequired,
};

export const InstagramDisplay = (props) => {
  const { contact, theme } = useSelector((state) => state.display);
  const { index } = props;

  const { tag } = contact[index];

  return (
    <ContactDisplay
      icon={<img src={img_display} alt="" className="has-width-40" />}
      index={index}
      link={`https://instagram.com/${tag}`}
      label="Instagram"
    >
      <ContactLink url={`https://instagram.com/${tag}`} color={theme.text.color} />
    </ContactDisplay>
  );
};

InstagramDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export const AddInstagramContact = () => <ContactButton label="Instagram" type={TYPE_INSTAGRAM_ID} img={img} />;

export default InstagramContact;
