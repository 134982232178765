// Libraries
import axios from 'axios';

// Hookcs
import { useAuth0 } from '@auth0/auth0-react';

const useAPI = () => {
  const {
    getAccessTokenSilently, isAuthenticated,
  } = useAuth0();

  const signal = axios.CancelToken.source();

  const handleError = (e) => {
    if (axios.isCancel(e)) {
      return null;
    }

    console.log(e);
    console.log(e.response);

    if (e.message === 'Network Error' || !e.response.data) {
      const err = new Error('Network Error');
      err.response = {
        data: 'error_general',
      };
      throw err;
    }

    throw e;
  };

  const get = async (url) => {
    try {
      let authToken = '';

      if (isAuthenticated) {
        authToken = await getAccessTokenSilently();
      }

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        cancelToken: signal.token,
      };

      const items = await axios.get(`${process.env.REACT_APP_API}${url}`, config);

      return items.data;
    } catch (e) {
      return handleError(e);
    }
  };

  const post = async (url, data) => {
    try {
      let authToken = '';

      if (isAuthenticated) {
        authToken = await getAccessTokenSilently();
      }

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        cancelToken: signal.token,
      };

      const items = await axios.post(`${process.env.REACT_APP_API}${url}`, data, config);

      return items.data;
    } catch (e) {
      return handleError(e);
    }
  };

  const patch = async (url, data) => {
    try {
      let authToken = '';

      if (isAuthenticated) {
        authToken = await getAccessTokenSilently();
      }

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        cancelToken: signal.token,
      };

      const items = await axios.patch(`${process.env.REACT_APP_API}${url}`, data, config);

      return items.data;
    } catch (e) {
      return handleError(e);
    }
  };

  const put = async (url, data) => {
    try {
      let authToken = '';

      if (isAuthenticated) {
        authToken = await getAccessTokenSilently();
      }

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        cancelToken: signal.token,
      };

      const items = await axios.put(`${process.env.REACT_APP_API}${url}`, data, config);

      return items.data;
    } catch (e) {
      return handleError(e);
    }
  };

  const del = async (url) => {
    try {
      let authToken = '';

      if (isAuthenticated) {
        authToken = await getAccessTokenSilently();
      }

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        cancelToken: signal.token,
      };

      const items = await axios.delete(`${process.env.REACT_APP_API}${url}`, config);

      return items.data;
    } catch (e) {
      return handleError(e);
    }
  };

  return {
    get,
    post,
    patch,
    put,
    del,
  };
};

export default useAPI;
