import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Constants
import { LOGIN } from 'constants/routes';

const ProfilePictureDemoPopup = () => {
  const { t } = useTranslation(['errors']);

  return (
    <div className="box boxshadow has-width-300 has-background-primary has-text-centered p-5">
      <p>
        {t('errors:error_create_account_before_profile_picture_upload')}
      </p>
      <Link
        to={LOGIN}
        className="button has-background-secondary has-text-weight-bold mt-5 mb-3"
      >
        {t('general:sign_up_now')}
      </Link>
    </div>
  );
};

export default ProfilePictureDemoPopup;
