import React, { useState } from 'react';

// Constants
import { MAX_NAME_LENGTH } from 'constants/contacts';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setName } from 'store/profile';

/*
 * Component that handles the name input
 */
const NameEditor = () => {
  const { name, theme } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const [error, setError] = useState('');

  /*
   * Validates and sets the name to state
   */
  const _setName = (_name) => {
    setError('');

    // check max length
    if (_name.length >= MAX_NAME_LENGTH) {
      setError('Maximale Länge erreicht!');
      return;
    }

    dispatch(setName(_name));
  };

  return (
    <div className="field mt-3" style={{ color: theme.text.color }}>
      <div className="control">
        <p>Name</p>
        <input
          onChange={(event) => _setName(event.target.value)}
          type="text"
          value={name}
          placeholder="Name"
          className="input is-size-5 has-text-weight-bold has-no-background has-text-centered"
          style={{ color: theme.text.color, border: `1px solid ${theme.text.color}` }}
        />
        <p className="has-text-centered" style={{ color: theme.text.color }}>
          { error }
        </p>
      </div>
    </div>
  );
};

export default NameEditor;
