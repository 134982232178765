import React from 'react';

// Hooks
import { useSelector } from 'react-redux';

// Components
import ProfilePicture from 'components/profile/ProfilePicture';
import WebsiteDisplay from './WebsiteDisplay';
import ContactList from './ContactList';

// Styles
import styles from './styles.module.scss';

const ProfileCard = () => {
  const {
    name, description, website, profilePic, contact, theme,
  } = useSelector((state) => state.display);

  return (
    <div
      id={styles.wrapper}
      className="boxshadow box br-5"
      style={{ backgroundColor: theme.profile.values[0], color: theme.text.color }}
    >
      <div className="mt-4">
        <ProfilePicture img={profilePic} contact={contact} color={theme.text.color} />
      </div>
      <div className="has-text-centered">
        <p
          className="has-text-weight-bold mt-3 is-size-4"

        >
          {name}
        </p>
      </div>
      <div className="has-text-centered mb-3">
        <p className="">
          {description}
        </p>
      </div>
      <div className="has-text-centered mb-3">
        <WebsiteDisplay website={website} color={theme.text.color} />
      </div>
      <div>
        <ContactList />
      </div>
    </div>
  );
};

export default ProfileCard;
