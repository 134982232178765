import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Constants
import { TYPE_SNAPCHAT_ID } from 'constants/contacts';

// Assets
import img from 'assets/images/icons/snapchat_icon.png';
import img_display from 'assets/images/icons/snapchat_icon_display.png';

// Components
import ContactField from '../../ContactField';
import ContactButton from '../../ContactButton';
import ContactDisplay from '../../ContactDisplay';
import ContactCopy from '../../ContactCopy';

const SnapchatContact = (props) => {
  const { index } = props;

  const { t } = useTranslation(['general']);

  const validate = (tag) => {
    let error = '';

    if (tag.length > 256) {
      error = t('general:error_enter_valid_tag');
    }

    return { error, tag };
  };

  return (
    <ContactField
      icon={<img src={img_display} alt="" className="has-width-40" />}
      index={index}
      validate={validate}
      label="Snapchat"
      placeholder="Name"
    />
  );
};

SnapchatContact.propTypes = {
  index: PropTypes.number.isRequired,
};

export const SnapchatDisplay = (props) => {
  const { contact, theme } = useSelector((state) => state.display);
  const { index } = props;

  const { tag } = contact[index];

  return (
    <ContactDisplay
      icon={<img src={img_display} alt="" className="has-width-40" />}
      index={index}
      link="https://snapchat.com/"
      label="Snapchat"
    >
      <ContactCopy text={tag} color={theme.text.color} />
    </ContactDisplay>
  );
};

SnapchatDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export const AddSnapchatContact = () => <ContactButton label="Snapchat" type={TYPE_SNAPCHAT_ID} img={img} />;

export default SnapchatContact;
