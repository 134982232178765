import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasData: false,
  isLoading: true,
  email: '',
  name: '',
  cookies: {
    showBanner: true,
    allowAnalytics: false,
  },
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  extraReducers: (builder) => {
    // clear creator on CLEAR_STATE
    builder.addCase('CLEAR_STATE', (state) => {
      state = initialState;
      return state;
    });
  },
  reducers: {
    setSession: (state, action) => ({
      ...state,
      hasData: true,
      isLoading: false,
      ...action.payload,
    }),
    setCookieBanner: (state, action) => {
      state.cookies.showBanner = action.payload;
    },
    setAnalyticsSettings: (state, action) => {
      state.cookies.allowAnalytics = action.payload;
    },
    clearSession: () => initialState,
  },
});

export const {
  setSession, clearSession, setCookieBanner, setAnalyticsSettings,
} = sessionSlice.actions;

export default sessionSlice.reducer;
