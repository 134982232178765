import React, { useEffect, useLayoutEffect } from 'react';

// Hooks
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

// Actions
import { clearProfile } from 'store/profile';

// Constants
import { HOME } from 'constants/routes';

// Components
import Loader from 'components/utils/Loader';
import ProfileEditor from '../ProfileEditor';

const ProfileDemo = () => {
  const dispatch = useDispatch();

  // clear profile on mount
  useLayoutEffect(() => {
    dispatch(clearProfile());
  }, []);

  return <ProfileEditor />;
};

export default ProfileDemo;
