import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Constants
import { TYPE_NINTENDO_SWITCH_ID } from 'constants/contacts';

// Hooks
import { useTranslation } from 'react-i18next';

// Assets
import img from 'assets/images/icons/nintendo_switch_icon.png';
import img_display from 'assets/images/icons/nintendo_switch_icon_display.png';

// Components
import ContactField from '../../ContactField';
import ContactButton from '../../ContactButton';
import ContactDisplay from '../../ContactDisplay';

const NintendoSwitchContact = (props) => {
  const { index } = props;

  const { t } = useTranslation(['contact', 'errors']);

  const validate = (tag) => {
    let error = '';
    if (tag.length > 64) {
      error = t('errors:error_enter_valid_tag');
    }

    return { error, tag };
  };

  return (
    <>
      <ContactField
        icon={<img src={img_display} alt="" className="has-width-40" />}
        index={index}
        validate={validate}
        label="Nintendo Switch Online"
        placeholder="Nintendo Switch Online ID"
      />

    </>
  );
};

NintendoSwitchContact.propTypes = {
  index: PropTypes.number.isRequired,
};

export const NintendoSwitchDisplay = (props) => {
  const { index } = props;

  return (
    <div>
      <ContactDisplay
        icon={<img src={img_display} alt="" className="has-width-40" />}
        index={index}
        link="https://nintendo.com"
        label="Nintendo Switch Online"
      />
    </div>
  );
};

NintendoSwitchDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export const AddNintendoSwitchContact = () => (
  <ContactButton
    type={TYPE_NINTENDO_SWITCH_ID}
    img={img}
    label="Nintendo Switch"
  />
);

export default NintendoSwitchContact;
