import React from 'react';

// Libraries
import ReactDOM from 'react-dom';
import { persistStore } from 'redux-persist';

// Providers
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Auth0Provider } from '@auth0/auth0-react';
import store from './store';

// Components
import App from './App';

// Constants
import { LOGIN } from './constants/routes';

// Styles
import './styles/main.scss';

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin + LOGIN}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
