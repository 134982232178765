import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';
import { sanitizeUrl } from '@braintree/sanitize-url';

const WebsiteDisplay = (props) => {
  const { website, color } = props;

  const click = () => {
    stats.push(['trackEvent', 'Website Link', 'clicked']);
  };

  const sanitizedUrl = useMemo(() => {
    let sanitizedWebsite = website;

    if (sanitizedWebsite.startsWith('http') === false) {
      sanitizedWebsite = `https://${sanitizedWebsite}`;
    }

    sanitizedWebsite = sanitizeUrl(sanitizedWebsite);

    return sanitizedWebsite;
  }, [website]);

  return (
    <a
      target="_blank"
      rel="noreferrer"
      className="cleanButton has-text-underlined"
      href={sanitizedUrl}
      onClick={click}
      style={{ color }}
    >
      {website}
    </a>
  );
};

WebsiteDisplay.propTypes = {
  website: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default WebsiteDisplay;
