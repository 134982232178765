import React from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Constants
import { TYPE_TIKTOK_ID } from 'constants/contacts';

// Assets
import img from 'assets/images/icons/tiktok_icon.png';
import img_display from 'assets/images/icons/tiktok_icon_display.png';

// Components
import ContactField from '../../ContactField';
import ContactButton from '../../ContactButton';
import ContactDisplay from '../../ContactDisplay';
import ContactLink from '../../ContactLink';
import ContactCopy from '../../ContactCopy';

const TikTokContact = (props) => {
  const { index } = props;

  const { t } = useTranslation(['errors']);

  const validate = (tag) => {
    let error = '';
    if (tag.length > 256) {
      error = t('errors:error_enter_valid_tag');
    }

    return { error, tag };
  };

  return (
    <ContactField
      icon={<img src={img_display} alt="" className="has-width-40" />}
      index={index}
      validate={validate}
      label="TikTok"
      placeholder="Name"
    />
  );
};

TikTokContact.propTypes = {
  index: PropTypes.number.isRequired,
};

export const TikTokDisplay = (props) => {
  const { contact, theme } = useSelector((state) => state.display);
  const { index } = props;

  const { tag } = contact[index];

  return (
    <ContactDisplay
      icon={<img src={img_display} alt="" className="has-width-40" />}
      index={index}
      link={`https://tiktok.com/@${tag}`}
      label="TikTok"
    >
      <ContactLink url={`https://tiktok.com/@${tag}`} color={theme.text.color} />
      <ContactCopy text={`${tag}`} color={theme.text.color} />
    </ContactDisplay>
  );
};

TikTokDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export const AddTikTokContact = () => <ContactButton label="TikTok" type={TYPE_TIKTOK_ID} img={img} />;

export default TikTokContact;
