import React from 'react';

const EnglishTOS = () => (
  <div>
    <h1 className="has-text-weight-bold is-size-5 mb-3">
      Conditions of Participation Giveaways / Sweepstakes
    </h1>
    <p><strong><span>1. Subject of the conditions of participation and organizer</span></strong></p>

    <p><span>(1)&nbsp;&nbsp;&nbsp;&nbsp; These Terms and Conditions of Participation govern the conditions for participation in giveaway promotions and raffles (hereinafter &quot;weepstake&quot;). The more detailed description and the procedure of the respective giveaways or sweepstakes are carried out within the framework of the respective sweepstakes action on the social media channels of Flowdust Online Services UG (haftungsbeschränkt) or via the platform Gleam.io.</span></p>

    <p><span>(2)&nbsp;&nbsp;&nbsp;&nbsp; Organizer of the sweepstake is the Flowdust Online Services UG (haftungsbeschränkt), Am Bahnhof 1, 95632 Wunsiedel.</span></p>

    <p><span>(3)&nbsp;&nbsp;&nbsp;&nbsp; By participating in the respective sweepstake, these terms and conditions are accepted.</span></p>

    <p><span>(4)&nbsp;&nbsp;&nbsp;&nbsp; The sweepstake is not connected to the operators of the social media platforms used, it is neither sponsored, supported nor organized by them.</span></p>

    <p><span>&nbsp;</span></p>

    <p><strong><span>2. Participation</span></strong></p>

    <p>
      <span>
        (1)&nbsp;&nbsp;&nbsp;&nbsp; Everyone who has reached the age of 18 is eligible to participate in the sweepstake. Local restrictions regarding participation may result from the respective sweepstake postings on social media.&nbsp; Excluded are employees of Flowdust Online Services UG (haftungsbeschränkt) and affiliated companies as well as relatives of such employees and their relatives. The participation with fake identities or with identities of third parties is not permitted.
        <br />
        <br />
      </span>

    </p>

    <p><span>(2)&nbsp;&nbsp;&nbsp;&nbsp; Eligible participants can participate in sweepstakes by performing the respective actions indicated on social media for participation, e.g., by linking the sweepstakes posting (&quot;Like-Me-Click&quot;) and in any case leaving this rating until the winners have been selected. Further conditions of participation may arise when using the platform Gleam.io and will be displayed to the user there.</span></p>

    <p><span>&nbsp;</span></p>

    <p>
      <strong>
        <span>3. </span>
        <span>Profits and profit notification</span>
      </strong>
    </p>

    <p><span>(1)&nbsp;&nbsp;&nbsp;&nbsp; Among all eligible participants, we will raffle the prizes listed in the respective contest posting on social media.&nbsp; </span></p>

    <p><span>(2)&nbsp;&nbsp;&nbsp;&nbsp; The winners will be randomly selected from all eligible participants within one week of the closing date. The winners will be notified via the comment function or in a separate posting and asked to send a social media direct message to the social media profile organizing the lottery or an e-mail to an e-mail address specified there, in which the personal data will be transmitted correctly and truthfully. This information will be used to send the prize. If the prize draw is won via the Gleam.io platform, notification can also be made by email notification from Gleam.io.</span></p>

    <p><span>(3)&nbsp;&nbsp;&nbsp;&nbsp; If we do not receive a social media direct message or e-mail from a winner within 14 days of receipt of the prize notification, the claim to the prize expires. In this case, we are entitled to carry out a replacement draw.</span></p>

    <p><span>(4)&nbsp;&nbsp;&nbsp;&nbsp; Each participant can only win one prize per competition.</span></p>

    <p><span>(5)&nbsp;&nbsp;&nbsp;&nbsp; The prize is not transferable, nor can the prize be exchanged or paid out in cash.&nbsp; </span></p>

    <p><span>(6)&nbsp;&nbsp;&nbsp;&nbsp; If circumstances arise for which we are not responsible, the respective winner accepts an appropriate substitute prize. Such circumstances for which we are not responsible are in particular those that are the responsibility of the sponsors of the prizes.</span></p>

    <p><span>(7)&nbsp;&nbsp;&nbsp;&nbsp; Winnings will be sent to the winner via email or social media direct message. In the case of physical items, the prize will be sent by mail to the address provided. The risk is transferred to the winner when the prize is handed over to a transport person. We are not responsible for delivery damages.</span></p>

    <p>
      <span>&nbsp;</span>
      <span>&nbsp;</span>
    </p>

    <p><strong><span>4. Granting of rights of use, liability and indemnification</span></strong></p>

    <p><span>(1)&nbsp;&nbsp;&nbsp;&nbsp; By accepting the prize, the winner agrees that we may use his/her name for advertising purposes.</span></p>

    <p><span>(2)&nbsp;&nbsp;&nbsp;&nbsp; The participant indemnifies us from claims of third parties of any kind resulting from the illegality of the content posted by the participant, if applicable, which the participant has used. The indemnification obligation also includes the obligation to fully indemnify the organizer from legal defense costs (e.g. court and attorney&apos;s fees).</span></p>

    <p><span>(3)&nbsp;&nbsp;&nbsp;&nbsp; By participating in the sweepstake, the participant releases the social media platform(s) used from any liability.</span></p>

    <p><span>&nbsp;</span></p>

    <p><strong><span>5. Exclusion</span></strong></p>

    <p><span>(1)&nbsp;&nbsp;&nbsp;&nbsp; Any violation of these conditions of participation entitles us to exclude the respective participant from participation.&nbsp; This applies in particular if the participant makes false statements or posted content (e.g. comments) violates applicable law or the rights of third parties. The same applies to comments that may be considered to glorify violence, be offensive, harassing or degrading or otherwise violate social decency.&nbsp; If bots or like-fraud are used to rate one&apos;s own comments, posts or likes, the participant can also be excluded from the competition. The same applies if a participant posts more than one comment under a competition entry. Multiple comments do not increase the chance of winning, but can lead to exclusion.</span></p>

    <p><span>(2)&nbsp;&nbsp;&nbsp;&nbsp; If the excluded participant is an already drawn winner, the prize can be subsequently withdrawn.</span></p>

    <p><span>&nbsp;</span></p>

    <p><strong><span>6. Premature termination and changes</span></strong></p>

    <p><span>We reserve the right to prematurely terminate the sweepstake in whole or in part at any time, even without adherence to deadlines, or to change its course if it is not possible to guarantee the proper execution of the sweepstake for technical (e.g. computer virus, manipulation of or errors in software/hardware) or legal reasons (e.g. prohibition by Facebook).</span></p>

    <p><span>&nbsp;</span></p>

    <p><strong><span>7. Privacy</span></strong></p>

    <p>
      <span>
        We are responsible for the collection, processing and use of personal data of the participants if we process them ourselves. We will use the personal data of the participant as well as his other personal data only within the scope of the legal regulations of the data protection law (DSGVO and BDSG new). We will only store, process and use the information to the extent that this is necessary for the execution of the sweepstake or the participant&apos;s consent has been obtained. This also includes use to exercise the rights of use granted. The data will be used exclusively for the execution of the sweepstake and then deleted. The participant may at any time request information about the data stored about him or her. In all other respects our data protection declaration applies accordingly, which can be
        <a href="https://tags.gg/privacy" className="ml-1"><ins>here.</ins></a>
      </span>
    </p>

    <p><span>&nbsp;</span></p>

    <p><strong><span>8. Final provisions</span></strong></p>

    <p><span>(1)&nbsp;&nbsp;&nbsp;&nbsp; Should the conditions of participation contain invalid provisions, the validity of the remaining provisions shall remain unaffected.</span></p>

    <p><span>(2)&nbsp;&nbsp;&nbsp;&nbsp; German law applies. Any legal recourse to review the draw is excluded.</span></p>

    <p><span>&nbsp;</span></p>
  </div>

);

export default EnglishTOS;
