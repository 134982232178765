import React, { useState } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Constants
import { LOGIN } from 'constants/routes';

// Components
import Popup from 'reactjs-popup';

const BackgroundImageDemoPopup = () => {
  const { t } = useTranslation(['errors']);

  return (
    <div className="">
      <Popup
        trigger={(
          <button
            type="button"
            className="cleanButton is-relative has-text-white p-0"
            style={{ zIndex: '996666' }}
            onClick={(e) => console.log(e)}
          >
            <input
              className="is-checkradio is-white"
              id="radioImage"
              type="radio"
              name=""
            />
            <label htmlFor="radioImage" className="has-text-weight-semibold" style={{ zIndex: '-1' }}>
              {t('general:image')}
            </label>
          </button>
        )}
        position="top center"
        on={['click']}
        keepTooltipInside="#root"
        repositionOnResize
        closeOnDocumentClick
        closeOnEscape
        modal
      >
        <div className="box boxshadow has-width-300 has-background-primary has-text-centered p-5">
          <p className="has-text-white has-text-weight-normal">
            {t('errors:error_create_account_before_background_image_upload')}
          </p>
          <Link
            to={LOGIN}
            className="button has-background-secondary has-text-weight-bold mt-5 mb-3"
          >
            {t('general:sign_up_now')}
          </Link>
        </div>
      </Popup>

    </div>
  );
};

export default BackgroundImageDemoPopup;
