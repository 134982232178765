import React, { useMemo, useState } from 'react';

// Libraries
import { v4 as uuidv4 } from 'uuid';
import stats from 'analytics/matomo';

// Hooks
import { useTranslation } from 'react-i18next';
import useAPI from 'hooks/useAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Actions
import { setTwitchState } from 'store/profile';

const TwitchConnection = () => {
  const { t } = useTranslation(['general']);

  const [status, setStatus] = useState();
  const [disabled, setDisabled] = useState();

  const dispatch = useDispatch();

  const history = useHistory();

  const { isConnected } = useSelector((state) => state.profile.connections.twitch);

  const twitchLink = useMemo(() => {
    if (isConnected) return '';

    const id = uuidv4();
    dispatch(setTwitchState(id));

    // reading scope
    const scope = 'analytics%3Aread%3Aextensions%20analytics%3Aread%3Agames%20bits%3Aread%20channel%3Aread%3Agoals%20channel%3Aread%3Ahype_train%20channel%3Aread%3Apolls%20channel%3Aread%3Apredictions%20channel%3Aread%3Asubscriptions%20user%3Aread%3Abroadcast%20user%3Aread%3Aemail%20chat%3Aread';

    /*
    %20channel%3Aread%3Avips%20user%3Aread%3Asubscriptions%20user%3Aread%3Afollows%20channel%3Aread%3Aeditors%20moderation%3Aread%20moderator%3Aread%3Ablocked_terms%20moderator%3Aread%3Achat_settings%20channel%3Aread%3Aredemptions
    */

    const redirectUri = `${window.location.origin}/connections/twitch`;

    return `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_TWITCH_CLIENT_ID}&state=${id}&redirect_uri=${redirectUri}&scope=${scope}`;
  }, [isConnected]);

  const { get, post } = useAPI();

  const connectTwitch = () => {
    stats.push(['trackEvent', 'Connections', 'Connect twitch button clicked']);
  };

  const disconnectTwitch = async () => {
    stats.push(['trackEvent', 'Connections', 'Disonnect twitch button clicked']);
  };

  return (
    <div className="is-flex align-items-center">

      <i className="fab fa-twitch is-size-4" style={{ color: '#772CE8' }} />
      <p className="mx-2">
        Twitch
      </p>
      <div className="has-margin-left-auto has-text-weight-bold">
        {
        isConnected
          ? (
            <p className="has-text-weight-bold">
              {t('general:connected')}
            </p>
          )
          : (
            <a
              href={twitchLink}
              rel="noreferrer"
              className="button has-text-white br4"
              onClick={connectTwitch}
              style={{ backgroundColor: '#772CE8' }}
            >
              {t('general:connect')}
            </a>
          )
      }
      </div>

    </div>
  );
};

export default TwitchConnection;
