import React from 'react';

// Libraries
import {
  Router,
  Route,
  Switch,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import stats from 'analytics/matomo';
import { HelmetProvider, Helmet } from 'react-helmet-async';

// Constants
import * as routes from 'constants/routes';

// Connections
import ConnectionsHub from 'components/connections/ConnectionsHub';

// Components
import TwitchConnectionResponseHandler from 'components/connections/TwitchConnectionResponseHandler';
import ProfileDemo from 'components/profile/ProfileDemo';
import CookieBanner from 'components/layout/CookieBanner';
import GiveawayTOS from 'components/layout/GiveawayTOS';
import Landing from './components/layout/Landing';
import Navigation from './components/layout/Navigation';
import NotFound from './components/layout/NotFound';

// Layout / basic components
import Login from './components/auth/Login';
import Home from './components/home/Home';
import Imprint from './components/layout/Imprint';
import PrivacyPolicy from './components/layout/PrivacyPolicy';

// Profile Display
import ProfileDisplay from './components/profile/ProfileDisplay';

// Languages
import './locales/i18n';

// Import icons here because on building, react does not like fontawesome files
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import '@fortawesome/fontawesome-free/css/brands.css';

const history = stats.connectToHistory(createBrowserHistory());

// stats.push(['requireConsent']);
stats.push(['enableHeartBeatTimer']);
stats.push(['trackPageView']);

const App = () => (
  <Router history={history}>
    <HelmetProvider>
      <Helmet>
        <title>TAGS.GG</title>
      </Helmet>
      <Navigation />
      <CookieBanner />
      <Switch>
        <Route
          exact
          path={routes.LANDING}
          component={() => <Landing />}
        />
        <Route
          exact
          path={routes.LOGIN}
          component={() => <Login />}
        />

        <Route
          exact
          path={routes.IMPRINT}
          component={() => <Imprint />}
        />

        <Route
          exact
          path={routes.GIVEAWAY}
          component={() => <GiveawayTOS />}
        />

        <Route
          exact
          path={routes.HOME}
          component={() => <Home />}
        />

        <Route
          exact
          path={routes.CONNECTIONS}
          component={() => <ConnectionsHub />}
        />

        <Route
          exact
          path={routes.CONNECTIONS_TWITCH_RESPONSE}
          component={() => <TwitchConnectionResponseHandler />}
        />

        <Route
          exact
          path={routes.DEMO}
          component={() => <ProfileDemo />}
        />

        <Route
          exact
          path={routes.PRIVACY}
          component={() => <PrivacyPolicy />}
        />

        <Route
          exact
          path={routes.NOT_FOUND}
          component={() => <NotFound />}
        />

        <Route
          path="*"
          render={() => <ProfileDisplay />}
        />
      </Switch>
    </HelmetProvider>
  </Router>
);

export default App;
