import React, { useState, useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';

// Styles
import styles from './styles.module.scss';

const BackgroundImage = (props) => {
  const { backgroundImg, type } = props;

  const [pic, setPic] = useState();

  useEffect(() => {
    const loadBackgroundImg = async () => {
      try {
        if (type !== 'image' || !backgroundImg || backgroundImg.length === 0) return;

        setPic(`${process.env.REACT_APP_FILE_API}/tagsgg/images/profile/${backgroundImg}.png`);
      } catch (e) {
        console.log(e);
        console.log(e.response);
      }
    };

    if (!backgroundImg) {
      setPic();
      return;
    }

    loadBackgroundImg();
  }, [backgroundImg, type]);

  if (type !== 'image') return null;

  return (
    <div id={styles.wrapper} className="is-fullheight" style={{ backgroundImage: `url(${pic})` }} />
  );
};

BackgroundImage.propTypes = {
  backgroundImg: PropTypes.string,
  type: PropTypes.string,
};

BackgroundImage.defaultProps = {
  backgroundImg: '',
  type: '',
};

export default BackgroundImage;
