import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Constants
import { TYPE_DISCORD_SERVER } from 'constants/contacts';

// Assets
import img from 'assets/images/icons/discord_server_icon.png';
import img_display from 'assets/images/icons/discord_server_icon_display.png';

// Components
import ContactField from '../../ContactField';
import ContactButton from '../../ContactButton';
import ContactDisplay from '../../ContactDisplay';

import ContactCopy from '../../ContactCopy';
import ContactLink from '../../ContactLink';

const DiscordServerContact = (props) => {
  const { index } = props;

  const { t } = useTranslation(['errors']);

  const validate = (tag) => {
    let error = '';
    if (tag.length > 256) {
      error = t('errors:error_enter_valid_tag');
    }

    return { error, tag };
  };

  return (
    <ContactField
      icon={<img src={img_display} alt="" className="has-width-40" />}
      index={index}
      validate={validate}
      label="Discord Server"
      placeholder="discord.gg/..."
      hasName
      namePlaceholder="Discord Server Name (optional)"
    />
  );
};

DiscordServerContact.propTypes = {
  index: PropTypes.number.isRequired,
};

export const DiscordServerDisplay = (props) => {
  const { index } = props;

  const { contact, theme } = useSelector((state) => state.display);
  const { tag } = contact[index];

  const url = useMemo(() => {
    let _url = tag;
    if (!_url.startsWith('http')) {
      _url = `https://${_url}`;
    }

    return _url;
  }, [tag]);

  return (
    <ContactDisplay
      icon={<img src={img_display} alt="" className="has-width-40" />}
      index={index}
      label="Discord Server"
      link={url}
    >
      <ContactLink url={url} color={theme.text.color} />
      <ContactCopy text={tag} color={theme.text.color} />
    </ContactDisplay>
  );
};

DiscordServerDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export const AddDiscordServerContact = () => <ContactButton label="Discord Server" type={TYPE_DISCORD_SERVER} img={img} className="br0" />;

export default DiscordServerContact;
