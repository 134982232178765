import React from 'react';
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Constants
import { TYPE_UBISOFT_NAME } from 'constants/contacts';

// Assets
import img from 'assets/images/icons/ubisoft_icon.svg';
import img_display from 'assets/images/icons/ubisoft_icon_display.svg';

// Components
import ContactField from '../../ContactField';
import ContactButton from '../../ContactButton';
import ContactDisplay from '../../ContactDisplay';
import ContactCopy from '../../ContactCopy';

const UbisoftContact = (props) => {
  const { index } = props;

  const { t } = useTranslation(['errors']);

  const validate = (tag) => {
    let error = '';

    if (tag.length > 64) {
      error = t('errors:error_enter_valid_tag');
    }

    return { error, tag };
  };

  return (
    <ContactField
      icon={<img src={img_display} alt="" className="has-background-white has-width-40" />}
      index={index}
      validate={validate}
      label="Ubisoft"
      placeholder="Name"
    />
  );
};

UbisoftContact.propTypes = {
  index: PropTypes.number.isRequired,
};

export const UbisoftDisplay = (props) => {
  const { contact, theme } = useSelector((state) => state.display);
  const { index } = props;

  const { tag } = contact[index];

  return (
    <ContactDisplay
      icon={<img src={img_display} alt="" className="has-background-white has-width-40" />}
      index={index}
      link="https://ubisoft.com"
      label="Ubisoft"
    >
      <ContactCopy text={`${tag}`} color={theme.text.color} />
    </ContactDisplay>
  );
};

UbisoftDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export const AddUbisoftContact = () => (
  <ContactButton
    className="has-background-white"
    type={TYPE_UBISOFT_NAME}
    img={img}
    label="Ubisoft"
  />
);

export default UbisoftContact;
