import React, { useMemo } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Hooks
import { useSelector } from 'react-redux';

// Components
import ContactCopy from 'components/contact/ContactCopy';
import { sanitizeUrl } from '@braintree/sanitize-url';

// Assets
import verifiedBadge from 'assets/images/icons/verified.png';

const ContactDisplay = (props) => {
  const {
    icon, label, index, link, children, isVerified,
  } = props;

  const sanitizedUrl = useMemo(() => (link ? sanitizeUrl(link) : ''), [link]);

  const { contact, theme } = useSelector((state) => state.display);

  if (!contact[index]) {
    return null;
  }

  const { tag, type } = contact[index];

  const name = contact[index].name || tag;

  const clickCopyTag = () => {
    stats.push(['trackEvent', 'Profile Display', `Copied ${type} tag`]);
  };

  return (
    <div className="field my-2" style={{ color: theme.text.color }}>
      <label className="label mb-0 is-flex align-items-center" style={{ color: theme.text.color }}>
        {label}
        { isVerified
                && <img src={verifiedBadge} className="has-width-16 has-height-16 ml-1" alt="" />}
      </label>
      <div className="control mt-1">
        <div className="columns is-vcentered is-centered is-mobile is-multiline">
          <div className="column is-narrow p-0 has-fullheight is-flex has-content-centered">
            <a
              href={sanitizedUrl}
              target="_blank"
              rel="noreferrer"
            >
              { icon }
            </a>
          </div>
          <div className="column has-maxWidth">
            <div className="has-no-background">
              <p className="has-text-weight-semibold break-word">
                {name}
              </p>
            </div>
          </div>
          <div className="column is-narrow p-0">
            { children || <ContactCopy text={tag} onClick={clickCopyTag} color={theme.text.color} /> }
          </div>
        </div>
      </div>
    </div>
  );
};

ContactDisplay.propTypes = {
  icon: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  link: PropTypes.string,
  children: PropTypes.node,
  isVerified: PropTypes.bool,
};

ContactDisplay.defaultProps = {
  children: null,
  link: '',
  isVerified: false,
};

export default ContactDisplay;
