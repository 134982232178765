import React, { useState, useEffect } from 'react';

// Libraries
import { Helmet } from 'react-helmet-async';
import axios from 'axios';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Actions
import { setProfileDisplay } from 'store/display';

// Constants
import {
  DEMO, LANDING, LOGIN, NOT_FOUND,
} from 'constants/routes';

// Components
import Loader from 'components/utils/Loader';
import Footer from 'components/layout/Footer';
import BackgroundImage from 'components/profile/BackgroundImage';
import ProfileCard from './ProfileCard';

const ProfileDisplay = () => {
  const { t } = useTranslation(['errors']);

  const location = useLocation();
  const history = useHistory();

  const {
    hasData, color, backgroundImg, link, profilePic, theme,
  } = useSelector((state) => state.display);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const loadProfile = async (profileID) => {
      try {
        setLoading(true);

        const loadedProfile = await axios.get(`${process.env.REACT_APP_API}/profile/${profileID}`);

        if (!loadedProfile || !loadedProfile.data) {
          history.push(NOT_FOUND);
          return;
        }

        dispatch(setProfileDisplay(loadedProfile.data));
        setLoading(false);
      } catch (e) {
        // setError(`${t('errors:error_profile_not_found')}.`);
        history.push(NOT_FOUND);
      }
    };

    const { pathname } = location;

    if (!pathname) {
      history.push(LANDING);
      return;
    }

    const id = pathname.replace('/', '');

    loadProfile(id);
  }, []);

  if (!hasData || loading) {
    return (
      <>
        <Helmet>
          <title>TAGS.GG | Profile</title>
        </Helmet>
        <Loader error={error} />
        <Footer />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${link} | Tags.gg`}</title>
        <meta name="robots" content="noindex" />
        {
          profilePic && (
            <>
              <meta
                property="og:image"
                content={`${process.env.REACT_APP_FILE_API}/tagsgg/images/profile/${profilePic}.png`}
              />
              <meta
                property="twitter:image"
                content={`${process.env.REACT_APP_FILE_API}/tagsgg/images/profile/${profilePic}.png`}
              />
            </>
          )
        }
      </Helmet>
      <div
        className="is-min-fullheight"
        style={{ color: theme.text.color, backgroundColor: theme.background.values[0] }}
      >
        <BackgroundImage backgroundImg={backgroundImg} type={theme.background.type} />
        <div className="py-6 has-fullheight is-relative">
          <div className="has-text-centered is-relative z-index-max">
            <Link
              className="boxshadow br5 p-4 is-inline-block"
              to={DEMO}
              style={{ backgroundColor: theme.profile.values[0] }}
            >
              <p
                style={{ color: theme.text.color }}
              >
                {`${t('general:claim_profile_now_too')}!`}
              </p>
              <p
                className="has-text-weight-bold mt-1"
                style={{ color: theme.text.color }}
              >
                TAGS.GG
              </p>
            </Link>

          </div>
          <div className="columns is-centered is-mobile">
            <div className="column is-narrow-desktop is-narrow-tablet is-12-mobile my-6">
              <ProfileCard />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default (ProfileDisplay);
