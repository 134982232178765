import React from 'react';

// Libraries
import { Link, useLocation } from 'react-router-dom';

// constants
import * as routes from 'constants/routes';
import { useTranslation } from 'react-i18next';
import { LOGIN, DEMO, LANDING } from 'constants/routes';
import tagsicon from 'assets/images/landing/noborder.png';

// styles
import styles from './styles.module.scss';

const Footer = () => {
  const { t } = useTranslation(['general']);

  const location = useLocation();

  return (
    <div>
      <div id={styles.footer} className="is-marginless has-background-Secondary is-multiline" />
      <div className="has-border-primary" />
      <div className="columns">
        <div className="column has-text-weight-bold has-text-white is-12-mobile p-0">

          <div className="columns has-fullheight">
            <div className="column is-narrow has-text-centered is-flex has-content-centered">
              <img className="" src={tagsicon} alt="" />
            </div>
            <div className="column is-narrow is-flex has-content-centered">
              <a
                href="https://tags.gg"
                target="_blank"
                rel="noreferrer"
                className="has-text-white"
              >
                © 2022 - TAGS.GG
              </a>
            </div>
          </div>

        </div>
        <div className="column has-text-centered is-flex has-content-centered">
          <a href="https://twitter.com/tags_gg" target="_blank" rel="noreferrer" className="has-text-white ml-1">
            <i className="my-3 is-size-4 has-text-info fa-brands fa-twitter" />
          </a>
        </div>
        <div className="column has-text-right">
          <Link
            className="has-text-white is-block m-3"
            to={routes.PRIVACY}
          >
            {t('general:privacy')}
          </Link>
          <Link
            className="has-text-white is-block m-3"
            to={routes.IMPRINT}
          >
            {t('general:imprint')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
