import React, { useState, useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Constants
import { TYPE_STEAM_ID } from 'constants/contacts';

// Hooks
import useAPI from 'hooks/useAPI';

// Assets
import icon from 'assets/images/icons/testicon.png';

// Styles
import styles from './styles.module.scss';

const ProfilePicture = (props) => {
  const { get } = useAPI();

  const { img, contact, color } = props;

  const [pic, setPic] = useState(icon);

  useEffect(() => {
    const loadProfilePic = async () => {
      try {
        if (img) {
          setPic(`${process.env.REACT_APP_FILE_API}/tagsgg/images/profile/${img}.png`);
          return;
        }

        const steam = contact.find((item) => item.type === TYPE_STEAM_ID);

        if (!steam) return;

        const data = await get(`/steam/profile/picture/${steam.tag}`);

        setPic(data);
      } catch (e) {
        setPic(icon);
        console.log(e);
        console.log(e.response);
      }
    };

    setPic(icon);
    loadProfilePic();
  }, [img]);

  return (
    <div id={styles.wrapper} style={{ border: `3px solid ${color}` }}>
      <img src={pic} alt="" id={styles.img} />
    </div>
  );
};

ProfilePicture.propTypes = {
  img: PropTypes.string.isRequired,
  contact: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  color: PropTypes.string.isRequired,
};

export default ProfilePicture;
