import React, { useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

// Hooks
import useAPI from 'hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setProfile } from 'store/profile';

// Assets
import defaultAvatar from 'assets/images/icons/testicon.png';
import stats from 'analytics/matomo';

const PictureUploader = ({ close }) => {
  const [imgData, setImgData] = useState();
  const [imgUrl, setImgUrl] = useState();

  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const { post } = useAPI();

  const { t } = useTranslation(['errors']);

  const saveProfilePic = async () => {
    try {
      setError('');
      setDisabled(true);

      const formData = new FormData();
      formData.append('profilePic', imgData);

      const profile = await post('/profile/picture/', formData);
      dispatch(setProfile(profile));

      stats.push(['trackEvent', 'Profile Editor', 'Set profile image']);

      setDisabled(false);
      setImgData();

      close();
    } catch (e) {
      setDisabled(false);

      console.log(e);

      setError(t(`errors:${e.response.data}`));
    }
  };

  const _setProfilePic = (file) => {
    // if file has been rejected, data will be empty
    if (file.length === 0) return;

    setError('');
    setImgData(file[0]);

    URL.revokeObjectURL(imgUrl);
    const url = URL.createObjectURL(file[0]);
    setImgUrl(url);
  };

  const onDropRejected = () => {
    setError(t('errors:error_invalid_file'));
  };

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: 'image/jpeg, image/png, image/gif',
    onDrop: _setProfilePic,
    maxFiles: 1,
    // max 1 MB
    maxSize: 1048576,
    onDropRejected,
  });

  return (
    <div className="box boxshadow has-width-300 has-background-primary has-text-centered">
      {
        imgUrl
        && (
          <div className="has-border-white-bold has-width-128 has-height-128 br100 has-overflow-hidden p-0 has-background-white mb-4 is-in-center">
            <img
              src={imgUrl}
              className="has-fullheight has-fullwidth"
              alt=""
            />
          </div>
        )
      }
      <div {...getRootProps({
        className: 'has-height-200 has-width-200 has-border-white-light is-flex has-content-centered is-in-center mt-5 br5',
      })}
      >
        <input {...getInputProps()} />
        <button
          type="button"
          className="cleanButton has-fullheight has-text-white has-text-weight-bold"
          disabled={disabled}
        >
          {t('general:upload_picture')}
        </button>
      </div>
      {
        imgData
        && (
        <div>
          <p className="mt-4 is-size-7 has-text-white">
            {t('general:image_disclaimer')}
          </p>
          <button
            type="button"
            className={`button has-no-background has-border-white-light has-text-white mt-5 ${disabled ? 'is-loading' : ''}`}
            disabled={disabled}
            onClick={saveProfilePic}
          >
            {t('general:save_profile_picture')}
          </button>
        </div>
        )
      }
      <p className="has-text-white has-text-weight-bold is-size-7 has-text-centered mt-3">
        {error}
      </p>
    </div>
  );
};

PictureUploader.propTypes = {
  close: PropTypes.func.isRequired,
};

export default PictureUploader;
