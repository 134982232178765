import React, { useEffect, useState } from 'react';

// Libraries
import PropTypes from 'prop-types';

const TimeDifferenceCalculator = (props) => {
  const { startTime, className } = props;

  const [difference, setDifference] = useState();

  useEffect(() => {
    const calculateDifference = () => {
      const start = new Date(startTime);
      const end = new Date();

      let diff = Math.abs(end - start);

      const ms = diff % 1000;
      diff = (diff - ms) / 1000;
      const ss = diff % 60;
      diff = (diff - ss) / 60;
      const mm = diff % 60;
      diff = (diff - mm) / 60;
      const hh = diff % 24;

      setDifference(`${hh < 10 ? (`0${hh}`) : hh}:${mm < 10 ? (`0${mm}`) : mm}:${ss < 10 ? (`0${ss}`) : ss}`);
    };

    const interval = setInterval(calculateDifference, 1000);

    calculateDifference();

    return () => {
      clearInterval(interval);
    };
  }, [startTime]);

  return (
    <p
      className={className}
    >
      { difference }

    </p>
  );
};

TimeDifferenceCalculator.propTypes = {
  startTime: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TimeDifferenceCalculator.defaultProps = {
  className: '',
};

export default TimeDifferenceCalculator;
