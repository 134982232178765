import React from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Constants
import { MAX_CONTACT_LENGTH } from 'constants/contacts';

// Hooks
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { addContact } from 'store/profile';

// Styles
import styles from './styles.module.scss';

const ContactButton = (props) => {
  const {
    type, img, className, disabled, label,
  } = props;

  const dispatch = useDispatch();

  const { contact } = useSelector((state) => state.profile);

  const _addContact = () => {
    if (contact.length >= MAX_CONTACT_LENGTH) return;
    dispatch(addContact(type));

    stats.push(['trackEvent', 'Profile Tag', `Added ${type} tag`]);

    // cheesy way of scrolling the profile creator div
    const scrollBar = document.getElementById('profileCreator');

    if (!scrollBar) return;

    // scrollBar.scrollTop = scrollBar.scrollHeight;
    scrollBar.scroll({
      top: scrollBar.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button
      type="button"
      className="cleanButton grow"
      id={styles.button}
      onClick={_addContact}
      disabled={disabled}
    >
      <img
        src={img}
        className={className}
        alt=""
      />
      {
        label && (
          <p
            className="is-size-7 has-text-white"
          >
            {label}
          </p>
        )
      }
    </button>
  );
};

ContactButton.propTypes = {
  type: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

ContactButton.defaultProps = {
  className: '',
  disabled: false,
  label: '',
};

export default ContactButton;
