import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Components
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';

// Constants
import { LOGIN } from 'constants/routes';

const SaveButtonDemoPopup = () => {
  const { t } = useTranslation(['errors']);

  const {
    theme,
  } = useSelector((state) => state.profile);

  return (
    <Popup
      trigger={(
        <div className="has-text-centered mt-4">
          <button
            type="button"
            className="button br4 has-text-white has-text-weight-bold has-no-background"
            style={{ color: theme.text.color, border: `2px solid ${theme.text.color}` }}
          >
            {t('general:save')}
          </button>
        </div>
        )}
      position="top center"
      on={['click']}
      keepTooltipInside="#root"
      repositionOnResize
      modal
      className=""
    >
      <div className="box boxshadow has-width-300 has-background-primary has-text-centered p-5">
        <p>
          {t('errors:error_create_account_before_saving_profile')}
        </p>
        <Link
          to={LOGIN}
          className="button has-background-secondary has-text-weight-bold mt-5 mb-3"
        >
          {t('general:sign_up_now')}
        </Link>
      </div>
    </Popup>

  );
};

export default SaveButtonDemoPopup;
