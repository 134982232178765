import React from 'react';
import PropTypes from 'prop-types';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Constants
import { TYPE_TWITCH_NAME } from 'constants/contacts';

// Assets
import img from 'assets/images/icons/twitch_icon.png';
import img_display from 'assets/images/icons/twitch_icon_display.png';

// Components
import ContactField from '../../ContactField';
import ContactButton from '../../ContactButton';
import ContactDisplay from '../../ContactDisplay';
import ContactLink from '../../ContactLink';
import ContactCopy from '../../ContactCopy';

const TwitchContact = (props) => {
  const { index } = props;

  const { t } = useTranslation(['errors']);

  const validate = (tag) => {
    let error = '';

    if (tag.length > 64) {
      error = t('errors:error_enter_valid_tag');
    }

    return { error, tag };
  };

  return (
    <ContactField
      icon={<img src={img_display} alt="" className="has-width-40" />}
      index={index}
      validate={validate}
      label="Twitch"
      placeholder="Twitch Name"
    />
  );
};

TwitchContact.propTypes = {
  index: PropTypes.number.isRequired,
};

export const TwitchDisplay = (props) => {
  const { contact, theme } = useSelector((state) => state.display);
  const { index } = props;

  const { tag } = contact[index];

  return (
    <ContactDisplay
      icon={<img src={img_display} alt="" className="has-width-40" />}
      index={index}
      link={`https://twitch.tv/${tag}`}
      label="Twitch"
    >
      <ContactLink url={`https://twitch.tv/${tag}`} color={theme.text.color} />
      <ContactCopy text={`https://twitch.tv/${tag}`} color={theme.text.color} />
    </ContactDisplay>
  );
};

TwitchDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export const AddTwitchContact = () => <ContactButton label="Twitch" type={TYPE_TWITCH_NAME} img={img} />;

export default TwitchContact;
