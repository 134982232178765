export const LANDING = '/';

export const HOME = '/home';
export const PROFILE = '/profile';
export const DEMO = '/demo';
export const CONNECTIONS = '/connections';
export const CONNECTIONS_TWITCH_RESPONSE = '/connections/twitch';

export const NOT_FOUND = '/not-found';

export const LOGIN = '/login';

export const IMPRINT = '/imprint';
export const PRIVACY = '/privacy';
export const GIVEAWAY = '/giveaway';
