import React, { useState, useEffect, useLayoutEffect } from 'react';

// Libraries
import withAuthentication from 'components/hocs/withAuthentication';
import { Helmet } from 'react-helmet-async';

// Hooks
import useAPI from 'hooks/useAPI';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

// Actions
import { setLoading, setProfile } from 'store/profile';

// Components
import Loader from 'components/utils/Loader';
import BackgroundImage from 'components/profile/BackgroundImage';
import GameSidebar from './GameSidebar';
import ProfileCard from './ProfileCard';
import ThemeEditor from './ThemeEditor';

// Styles
import styles from './styles.module.scss';

const ProfileEditor = () => {
  const {
    isLoading: loading, contact, backgroundImg, theme,
  } = useSelector((state) => state.profile);

  const [status, setStatus] = useState('');

  const dispatch = useDispatch();

  const { isAuthenticated, isLoading } = useAuth0();

  const { get } = useAPI();

  useLayoutEffect(() => {
    // scroll to top on mount
    const scrollBar = document.getElementById('profileCreator');
    if (!scrollBar) return;
    scrollBar.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    const loadProfile = async () => {
      try {
        dispatch(setLoading(true));
        const profile = await get('/profile');

        dispatch(setProfile(profile));
      } catch (e) {
        dispatch(setLoading(false));
        setStatus(e.response.data);
      }
    };

    // if user is not authenticated and the useAuth0 loading is done, set loading to false
    // otherwise, we just wait
    if (!isAuthenticated && !isLoading) {
      dispatch(setLoading(false));
      return;
    }

    if (isLoading) return;

    loadProfile();
  }, [isAuthenticated, isLoading]);

  if (status || loading || isLoading) return <Loader error={status} />;

  return (
    <>
      <Helmet>
        <title>TAGS.GG - Profile</title>
      </Helmet>
      <div
        className="is-relative is-fullheight has-text-white has-overflow-hidden"
        style={{ backgroundColor: theme.background.values[0] }}
      >
        <BackgroundImage backgroundImg={backgroundImg} type={theme.background.type} />
        <div className="columns is-mobile is-multiline">
          <div className="column is-narrow py-0 px-0">
            <GameSidebar />
          </div>
          <div className="column p-0">
            <div className="columns is-multiline is-centered is-relative is-mobile" id="profileCreator">
              <div
                id={styles.profileCard}
                className="column is-fullheight has-overflow-y-auto"
              >
                <ProfileCard />
              </div>
              <div className="column is-narrow p-0">
                <ThemeEditor />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileEditor;
