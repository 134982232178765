import React, { useState, useEffect } from 'react';

// Actions
import { setSession } from 'store/session';

// Constants
import { LOGIN } from 'constants/routes';

// Components
import Loader from 'components/utils/Loader';

// HOCs
import { withAuthenticationRequired } from '@auth0/auth0-react';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useAPI from 'hooks/useAPI';

/**
 * This HOC checks if the user is authenticated and has data.
 * If he is not, the user will be redirected to the auth0 sign in
 * Then, load data from the db
 */
const withAuthentication = (Component) => {
  const WithAuthentication = (props) => {
    const { hasData } = useSelector((state) => state.session);

    const history = useHistory();
    const dispatch = useDispatch();
    const { get } = useAPI();

    const [error, setError] = useState('');

    useEffect(() => {
      const loadData = async () => {
        try {
          const user = await get('/users');

          dispatch(setSession(user));
        } catch (err) {
          if (err.response && err.response.status === 404) {
            history.push(LOGIN);
            return;
          }

          if (!err.response || !err.response.data) return;

          setError(err.response.data);
        }
      };

      if (!hasData) {
        loadData();
      }
    }, []);

    if (!hasData) {
      return <Loader error={error} />;
    }

    return <Component {...props} />;
  };

  return withAuthenticationRequired(WithAuthentication);
};

export default withAuthentication;
