import React, { useState, useMemo, useEffect } from 'react';

// Libraries
import stats from 'analytics/matomo';

// Hooks
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

// Actions
import {
  setBackgroundColor, setBackgroundType, setProfileColor, setTextColor, setShowThemeEditor,
} from 'store/profile';

// Style
import ColorPicker from 'components/utils/ColorPicker';
import ImageUploader from './ImageUploader';
import BackgroundImageDemoPopup from './BackgroundImageDemoPopup';

import styles from './styles.module.scss';
import SaveButton from '../SaveButton';
import SaveButtonDemoPopup from '../ProfileCard/SaveButtonDemoPopup';

const ThemeEditor = () => {
  const { theme, backgroundImg, showThemeEditor } = useSelector((state) => state.profile);
  const { text, profile, background } = theme;

  const dispatch = useDispatch();

  const { t } = useTranslation(['general']);

  const [colorToEdit, setColorToEdit] = useState('profile_color');
  const [disabled, setDisabled] = useState(false);

  const { isAuthenticated } = useAuth0();

  const showBackgroundImageEditor = background.type === 'image';

  // hide theme editor on unmount
  useEffect(() => () => {
    dispatch(setShowThemeEditor(false));
  }, []);

  useEffect(() => {
    // when popup closes, reset to profile color
    if (showThemeEditor === false) {
      setColorToEdit('profile_color');

      // if there is no background img, change background type back to color
      if (showBackgroundImageEditor && !backgroundImg) {
        dispatch(setBackgroundType('color'));
      }
    }
  }, [showThemeEditor]);

  const _setTextColor = ({ hex }) => {
    dispatch(setTextColor(hex));
  };

  const _setProfileColor = ({ hex }) => {
    dispatch(setProfileColor(hex));
  };

  const _setBackgroundColor = ({ hex }) => {
    dispatch(setBackgroundColor(hex));
  };

  const sendAnalytics = () => {
    stats.push(['trackEvent', 'Profile', `Set profile ${colorToEdit} color`]);
  };

  const setBackgroundTypeColor = () => {
    setColorToEdit('background');
    dispatch(setBackgroundType('color'));
  };

  const setBackgroundTypeImage = () => {
    setColorToEdit('background');
    dispatch(setBackgroundType('image'));
  };

  const setEditProfileColor = () => {
    setColorToEdit('profile_color');
  };

  const setEditTextColor = () => {
    setColorToEdit('text_color');
  };

  const color = useMemo(() => {
    if (colorToEdit === 'background') return background.values[0];

    if (colorToEdit === 'profile_color') return profile.values[0];

    return text.color;
  }, [colorToEdit, theme]);

  const setColor = useMemo(() => {
    if (colorToEdit === 'background') return _setBackgroundColor;

    if (colorToEdit === 'profile_color') return _setProfileColor;

    return _setTextColor;
  }, [colorToEdit]);

  const hideThemeEditor = () => {
    dispatch(setShowThemeEditor(false));
  };

  const toggleOpen = () => {
    dispatch(setShowThemeEditor(!showThemeEditor));
  };

  const showBackgroundEditor = () => {
    if (colorToEdit !== 'background') return null;

    if (showBackgroundImageEditor) return <ImageUploader disabled={disabled} setDisabled={setDisabled} />;

    return (
      <ColorPicker
        color={color}
        onChange={setColor}
        onChangeComplete={sendAnalytics}
        presetColors={['#292841', '#7c0404', '#120981', '#0a6828', '#492207', '#500880', '#113a53']}
      />
    );
  };

  const showSaveButton = () => {
    if (showBackgroundImageEditor && colorToEdit === 'background') return null;

    if (isAuthenticated) return <SaveButton dontShowColor />;

    return <SaveButtonDemoPopup />;
  };

  return (

    <div
      className={`has-background-primary has-text-white box br0 p-0 is-relative has-overflow-y-auto is-fullheight ${showThemeEditor ? (`${styles.open}`) : ''}`}
      style={{ zIndex: '999999' }}
      id={styles.themeEditor}
    >
      <div className="p-5">
        <div className="is-flex mb-5">

          <div className="is-flex has-content-centered">
            <i className="fas fa-palette mr-2" />
            <p className="has-text-white is-size-6 has-text-weight-bold">

              {t('general:theme')}
            </p>
          </div>

          <button
            type="button"
            onClick={hideThemeEditor}
            className="cleanButton has-text-white has-text-underlined has-margin-left-auto"
            disabled={disabled}
          >
            <i className="fas fa-times is-size-6" />
          </button>
        </div>

        <div className="px-3 pb-3 pt-2">
          <div className="">

            <div className="is-flex has-content-centered mx-1 mb-1">
              <i className="fas fa-user mr-2" />
              <p className="has-text-white has-text-weight-semibold mb-1">
                {t('general:profile_color')}
              </p>
            </div>

            <div className="has-text-centered">
              <button
                type="button"
                onClick={setEditProfileColor}
                className={`button grow has-width-32 has-height-32 br100 boxshadow p-0 mb-3 ${colorToEdit === 'profile_color' ? 'has-border-white' : 'has-border-grey-light-semibold'}`}
                style={{ backgroundColor: profile.values[0] }}
                aria-label={t('general:profile_color')}
              />
            </div>

            { colorToEdit === 'profile_color' && (
              <ColorPicker
                color={color}
                onChange={setColor}
                onChangeComplete={sendAnalytics}
                presetColors={['#292841', '#7c0404', '#120981', '#0a6828', '#492207', '#500880', '#113a53']}
              />
            )}

          </div>
        </div>

        <div className="px-3 pb-3 mt-2">
          <div className="">

            <div className="is-flex has-content-centered mx-1 mb-1">
              <i className="fas fa-paintbrush mr-2" />
              <p className="has-text-white has-text-weight-semibold mb-1">
                {t('general:text_color')}
              </p>
            </div>

            <div className="has-text-centered">
              <button
                type="button"
                onClick={setEditTextColor}
                className={`button grow has-width-32 has-height-32 br100 boxshadow p-0 mb-3 ${colorToEdit === 'text_color' ? 'has-border-white' : 'has-border-grey-light-semibold'}`}
                style={{ backgroundColor: text.color }}
                aria-label={t('general:text_color')}
              />
            </div>

            { colorToEdit === 'text_color' && (
              <ColorPicker
                color={color}
                onChange={setColor}
                onChangeComplete={sendAnalytics}
                presetColors={['#292841', '#7c0404', '#120981', '#0a6828', '#492207', '#500880', '#113a53']}
              />
            )}

          </div>
        </div>

        <div className="has-text-centered">
          <div
            className="is-inline-block"
          >

            <div className="is-flex mb-5 has-text-white">
              <div className="has-text-left has-text-weight-semibold is-flex align-items-center">
                <i className="fas fa-image mr-2" />
                <p>
                  {t('general:background')}
                </p>
              </div>
            </div>

            <div className="field has-text-white has-text-left">

              <div className="mb-1">
                <input
                  className="is-checkradio is-white"
                  id="radioColor"
                  type="radio"
                  name="backgroundTypeRadio"
                  onChange={setBackgroundTypeColor}
                  onClick={setBackgroundTypeColor}
                  checked={showBackgroundImageEditor === false}
                />
                <label htmlFor="radioColor" className="has-text-weight-semibold">
                  {t('general:color')}
                  <div
                    className="button boxshadow ml-2 has-width-32 has-height-32 br100 boxshadow p-0 mb-3 has-border-white"
                    style={{ backgroundColor: background.values[0] }}
                  />
                </label>
              </div>

              {
                    isAuthenticated
                      ? (
                        <div>
                          <input
                            className="is-checkradio is-white"
                            id="radioImage"
                            type="radio"
                            name="backgroundTypeRadio"
                            onChange={setBackgroundTypeImage}
                            onClick={setBackgroundTypeImage}
                            checked={showBackgroundImageEditor === true}
                          />
                          <label htmlFor="radioImage" className="has-text-weight-semibold">
                            {t('general:image')}
                          </label>
                        </div>
                      )
                      : <BackgroundImageDemoPopup />
                  }
            </div>

          </div>
          <div className="mt-4">
            { showBackgroundEditor() }
          </div>

          { showSaveButton() }

        </div>
      </div>

    </div>

  );
};

export default ThemeEditor;
