import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

// Hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAPI from 'hooks/useAPI';

// Constants
import { TYPE_TWITCH_CONNECTION } from 'constants/contacts';

// Assets
import img from 'assets/images/icons/twitch_icon.png';
import img_display from 'assets/images/icons/twitch_icon_display.png';
import verifiedBadge from 'assets/images/icons/verified.png';

// Actions
import { removeContact, setContact, setContactInfo } from 'store/profile';

// Components
import { sanitizeUrl } from '@braintree/sanitize-url';
import TimeDifferenceCalculator from 'components/utils/TimeDifferenceCalculator';
import TimeAgoDisplay from 'components/utils/TimeAgoDisplay';
import stats from 'analytics/matomo';
import ContactButton from '../../ContactButton';
import ContactLink from '../../ContactLink';
import ContactCopy from '../../ContactCopy';

const TwitchConnectionContact = (props) => {
  const { index } = props;

  const { t } = useTranslation(['general']);
  const { contact, theme } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const { get } = useAPI();

  const { info } = contact[index];

  useEffect(() => {
    if (!info) return;
    // set tag when we have info
    dispatch(setContact({ id: index, tag: info.twitchName }));
  }, [info]);

  useEffect(() => {
    if (info) return;

    const loadConnectionInfo = async () => {
      try {
        const connection = await get('/connections/twitch');
        dispatch(setContactInfo({ id: index, info: connection }));
      } catch (e) {
        console.log(e);
        console.log('Could not load Twitch Connection');
      }
    };

    loadConnectionInfo();
  }, [info]);

  const sanitizedUrl = useMemo(() => (info && info.twitchName ? sanitizeUrl(`https://twitch.tv/${info.twitchName}`) : ''), [info]);

  const deleteItem = () => {
    stats.push(['trackEvent', 'Contact Field', 'Tag Twitch Connection deleted']);
    dispatch(removeContact(index));
  };

  if (!info) {
    return (
      <div className="field my-4">
        <label className="label mb-0 is-flex align-items-center" style={{ color: theme.text.color }}>
          <p>
            Twitch
          </p>
          <img src={verifiedBadge} className="has-width-20 has-height-20 ml-2" alt="" />
        </label>
        <div className="control mt-1">
          <div className="columns is-vcentered is-centered is-mobile is-multiline">
            <div className="column is-narrow p-0 has-fullheight is-flex has-content-centered">
              <a
                href={sanitizedUrl}
                target="_blank"
                rel="noreferrer"
                className="is-flex align-items-center"
              >
                <img
                  alt=""
                  src={img_display}
                  className="has-width-40 br100"
                />
              </a>
            </div>
            <div className="column has-maxWidth">
              <div className="has-no-background" style={{ color: theme.text.color }}>
                <p className="has-text-weight-semibold break-word">
                  { `${t('general:loading')}...` }
                </p>
              </div>
            </div>
            <div className="column is-narrow p-0">
              <button
                type="button"
                onClick={deleteItem}
                className="cleanButton has-text-danger px-0"
              >
                <i className="fas fa-times" />
              </button>
            </div>
          </div>

        </div>
      </div>
    );
  }

  const {
    twitchName, profileImg, isOnline, startedAt, category, title,
  } = info;

  return (
    <div className="field my-4 has-max-width-330">
      <label className="label mb-0 is-flex align-items-center" style={{ color: theme.text.color }}>
        { profileImg && (
          <img
            alt=""
            src={img_display}
            className="has-width-20 mr-2"
          />
        )}
        <p>
          Twitch
        </p>
        <img src={verifiedBadge} className="has-width-20 has-height-20 ml-2" alt="" />
      </label>
      <div className="control mt-1">
        <div className="columns is-vcentered is-centered is-mobile is-multiline">
          <div className="column is-narrow p-0 has-fullheight is-flex has-content-centered">
            <a
              href={sanitizedUrl}
              target="_blank"
              rel="noreferrer"
              className="is-flex align-items-center"
            >
              <img
                alt=""
                src={profileImg || img_display}
                className="has-width-40 br100"
              />
            </a>
          </div>
          <div className="column has-maxWidth">
            <div className="has-no-background" style={{ color: theme.text.color }}>
              <p className="has-text-weight-semibold break-word">
                { twitchName }
              </p>
            </div>
          </div>
          <div className="column is-narrow p-0">
            <button
              type="button"
              onClick={deleteItem}
              className="cleanButton has-text-danger px-0"
            >
              <i className="fas fa-times" />
            </button>
          </div>
        </div>
      </div>
      {
        isOnline
          ? (
            <div>

              <div className="is-flex mt-2">
                <div className="is-flex has-max-width-80-percent">
                  <p className="has-text-weight-bold abbreviate-text">
                    { title }
                  </p>
                </div>
                <div className="is-flex has-margin-left-auto">
                  <p
                    className="br4 px-2 pb-1 has-text-white has-text-weight-bold"
                    style={{ backgroundColor: '#eb0300' }}
                  >
                    {t('general:live')}
                  </p>
                </div>
              </div>

              { category
                && (
                <div className="is-flex">
                  <p className="mr-1">
                    {t('general:plays').toLowerCase()}
                  </p>
                  <p
                    className="has-text-weight-bold abbreviate-text"
                  >
                    { category }
                  </p>
                </div>
                )}

              <div className="is-flex">
                <p className="mr-1">
                  {t('general:streaming_for').toLowerCase()}
                </p>
                <TimeDifferenceCalculator startTime={startedAt} className="has-text-weight-bold abbreviate-text" />
              </div>

            </div>
          )
          : (
            <div className="is-flex mt-2">
              <div className="is-flex has-margin-left-auto">
                <p className="has-background-grey-dark br5 px-2 py-1 has-text-white has-text-weight-semibold">
                  {t('general:offline')}
                </p>
              </div>
            </div>
          )
      }
    </div>
  );
};

TwitchConnectionContact.propTypes = {
  index: PropTypes.number.isRequired,
};

export const TwitchConnectionDisplay = (props) => {
  const { t } = useTranslation(['general']);
  const { contact, theme } = useSelector((state) => state.display);
  const { index } = props;

  const { tag, info } = contact[index];
  const {
    twitchName, profileImg, isOnline, startedAt, category, title,
  } = info;

  const sanitizedUrl = useMemo(() => (twitchName ? sanitizeUrl(`https://twitch.tv/${twitchName}`) : ''), [twitchName]);

  return (
    <div className="field my-4" style={{ color: theme.text.color }}>
      <label className="label mb-0 is-flex align-items-center">
        { profileImg && (
          <img
            alt=""
            src={img_display}
            className="has-width-20 mr-2"
          />
        )}
        <p
          style={{ color: theme.text.color }}
        >
          Twitch
        </p>
        <img src={verifiedBadge} className="has-width-20 has-height-20 ml-2" alt="" />
      </label>
      <div className="control mt-1">
        <div className="columns is-vcentered is-centered is-mobile is-multiline">
          <div className="column is-narrow p-0 has-fullheight is-flex has-content-centered">
            <a
              href={sanitizedUrl}
              target="_blank"
              rel="noreferrer"
              className="is-flex align-items-center"
            >
              <img
                alt=""
                src={profileImg || img_display}
                className="has-width-40 br100"
              />
            </a>
          </div>
          <div className="column has-maxWidth">
            <div className="has-no-background">
              <p className="has-text-weight-semibold break-word">
                { twitchName }
              </p>
            </div>
          </div>
          <div className="column is-narrow p-0">
            <ContactLink url={`https://twitch.tv/${tag}`} color={theme.text.color} />
            <ContactCopy text={`https://twitch.tv/${tag}`} color={theme.text.color} />
          </div>
        </div>
      </div>
      {
        isOnline
          ? (
            <div>

              <div className="is-flex mt-2">
                <div className="is-flex has-max-width-80-percent">
                  <p className="has-text-weight-bold abbreviate-text">
                    { title }
                  </p>
                </div>
                <div className="is-flex has-margin-left-auto">
                  <p
                    className="br4 px-2 pb-1 has-text-white has-text-weight-bold"
                    style={{ backgroundColor: '#eb0300' }}
                  >
                    {t('general:live')}
                  </p>
                </div>
              </div>

              <div className="is-flex">
                <p className="mr-1">
                  {t('general:plays').toLowerCase()}
                </p>
                <p
                  className="has-text-weight-bold abbreviate-text"
                >
                  { category }
                </p>
              </div>

              <div className="is-flex">
                <p className="mr-1">
                  {t('general:streaming_for').toLowerCase()}
                </p>
                <TimeDifferenceCalculator startTime={startedAt} className="has-text-weight-bold abbreviate-text" />
              </div>

            </div>
          )
          : (
            <div className="is-flex mt-2">
              <div className="is-flex has-margin-left-auto">
                <p className="has-background-grey-dark br5 px-2 py-1 has-text-white has-text-weight-semibold">
                  {t('general:offline')}
                </p>
              </div>
            </div>
          )
      }
    </div>
  );
};

TwitchConnectionDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export const AddTwitchConnectionContact = () => {
  const { contact } = useSelector((state) => state.profile);

  const hasTwitchConnectionTag = useMemo(() => {
    const index = contact.findIndex((_contact) => _contact.type === TYPE_TWITCH_CONNECTION);

    return index !== -1;
  }, [contact]);

  return (
    <ContactButton alt="Twitch" label="Twitch" type={TYPE_TWITCH_CONNECTION} img={img} disabled={hasTwitchConnectionTag} />
  );
};

export default TwitchConnectionContact;
