import React from 'react';

// Hooks
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const SignOutButton = () => {
  const { t } = useTranslation(['general']);

  const { logout } = useAuth0();
  const dispatch = useDispatch();

  const _logout = () => {
    // clear complete state on logout
    dispatch({ type: 'CLEAR_STATE' });
    logout({ returnTo: window.location.origin });
  };

  return (
    <button
      className="button has-background-secondary has-text-white"
      type="button"
      onClick={_logout}
    >
      <p className="mr-2 has-text-weight-semibold">
        {t('general:sign_out')}
      </p>
      <i className="fas fa-power-off has-text-white is-size-5" />
    </button>
  );
};

export default SignOutButton;
