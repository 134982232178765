import React from 'react';
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { TYPE_DISCORD_ID } from 'constants/contacts';

// Assets
import img from 'assets/images/icons/discord_icon.png';

// Components
import ContactField from '../../ContactField';
import ContactButton from '../../ContactButton';
import ContactDisplay from '../../ContactDisplay';

const DiscordContact = (props) => {
  const { index } = props;

  const { t } = useTranslation(['errors']);

  const validate = (tag) => {
    let error = '';
    if (tag.length > 37) {
      error = t('errors:error_enter_valid_tag');
    }

    return { error, tag };
  };

  return (
    <ContactField
      icon={<i className="fab fa-discord is-size-3 has-text-white" />}
      index={index}
      validate={validate}
      label="Discord"
      placeholder="Name#1337"
    />
  );
};

DiscordContact.propTypes = {
  index: PropTypes.number.isRequired,
};

export const DiscordDisplay = (props) => {
  const { index } = props;

  return (
    <ContactDisplay
      icon={<i className="fab fa-discord is-size-3 has-text-white" />}
      index={index}
      link="https://discord.gg"
      label="Discord"
    />
  );
};

DiscordDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export const AddDiscordContact = () => <ContactButton label="Discord" type={TYPE_DISCORD_ID} img={img} />;

export default DiscordContact;
