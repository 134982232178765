import React from 'react';
import PropTypes from 'prop-types';

// Hooks
import { useTranslation } from 'react-i18next';

// Constants
import { TYPE_ORIGIN_ID } from 'constants/contacts';

// Assets
import img from 'assets/images/icons/origin_icon.png';
import img_display from 'assets/images/icons/origin_icon_display.png';

// Components
import ContactField from '../../ContactField';
import ContactButton from '../../ContactButton';
import ContactDisplay from '../../ContactDisplay';

const OriginContact = (props) => {
  const { index } = props;

  const { t } = useTranslation(['errors']);

  const validate = (tag) => {
    let error = '';
    if (tag.length > 128) {
      error = t('errors:error_enter_valid_tag');
    }

    return { error, tag };
  };

  return (
    <ContactField
      icon={<img src={img_display} alt="" className="has-width-40" />}
      index={index}
      validate={validate}
      label="Origin"
      placeholder="Name"
    />
  );
};

OriginContact.propTypes = {
  index: PropTypes.number.isRequired,
};

export const OriginDisplay = (props) => {
  const { index } = props;

  return (
    <ContactDisplay
      icon={<img src={img_display} alt="" className="has-width-40" />}
      index={index}
      link="https://origin.com"
      label="Origin"
    />
  );
};

OriginDisplay.propTypes = {
  index: PropTypes.number.isRequired,
};

export const AddOriginContact = () => <ContactButton label="Origin" type={TYPE_ORIGIN_ID} img={img} />;

export default OriginContact;
