import React, { useEffect } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import Footer from 'components/layout/Footer';
import { Link } from 'react-router-dom';

// Constants
import { LOGIN, DEMO } from 'constants/routes';

// styles
import profile_example from 'assets/images/landing/profile_alt.png';
import streamer from 'assets/images/landing/streamer.png';
import gamer from 'assets/images/landing/gamer.png';
import esports from 'assets/images/landing/esports.png';
import twitch_example from 'assets/images/landing/twitch_example.png';

const Landing = () => {
  const { t } = useTranslation('general');

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="is-min-fullheight">
        <div className="columns is-min-fullheight is-centered is-vcentered">
          <div className="column is-4-desktop is-6-tablet has-text-white has-text-centered-mobile">
            <h1 className="is-size-1-desktop is-size-2-tablet is-size-4-mobile has-text-white has-text-weight-bold">
              Tag it. Send it. Play it.
            </h1>
            <p className="has-text-weight-bold mb-3">
              {t('general:landing_header')}
            </p>
            <p>
              {t('general:landing_explanation')}
            </p>
            <div className="">
              <Link
                to={DEMO}
                type="button"
                className="button has-fullheight grow has-background-secondary has-text-weight-bold my-3 white-space-normal"
              >
                <i className="fas fa-play-circle mr-2" />
                {t('general:no_signup_demo_info')}
              </Link>
            </div>
          </div>
          <div className="column is-4-desktop is-5-tablet has-text-centered">
            <img src={profile_example} alt="" />
          </div>
        </div>

        <div className="mt-5">
          <div className="has-text-centered">
            <h3
              className="is-size-1-desktop is-size-2-tablet is-size-4-mobile has-text-white has-text-weight-bold"
            >
              {t('general:features')}
            </h3>
          </div>

          <div className="columns is-centered is-mobile pt-4 pb-6">
            <div className="column is-9-desktop is-9-tablet is-11-mobile">
              <div className="columns is-centered is-mobile is-multiline">
                <div className="column has-min-width-300 is-3-desktop is-3-tablet is-12-mobile has-text-centered">
                  <i className="fas fa-square-share-nodes is-size-3 mb-2" />
                  <p
                    className="has-text-weight-bold mb-2"
                  >
                    {t('general:gamer_tags_and_socials')}
                  </p>
                  <p>
                    {t('general:gamer_tags_and_socials_description')}
                  </p>
                </div>
                <div className="column has-min-width-300 is-3-desktop is-3-tablet is-12-mobile has-text-centered">
                  <i className="fas fa-palette is-size-3 mb-2" />
                  <p
                    className="has-text-weight-bold mb-2"
                  >
                    {t('general:make_it_yours')}
                  </p>
                  <p>
                    {t('general:make_it_yours_description')}
                  </p>
                </div>
                <div className="column has-min-width-300 is-3-desktop is-3-tablet is-12-mobile has-text-centered">
                  <i className="fas fa-mobile-screen-button is-size-3 mb-2" />
                  <p
                    className="has-text-weight-bold mb-2"
                  >
                    {t('general:for_all_devices')}
                  </p>
                  <p>
                    {t('general:for_all_devices_description')}
                  </p>
                </div>
                <div className="column has-min-width-300 is-3-desktop is-3-tablet is-12-mobile has-text-centered">
                  <i className="fas fa-gears is-size-3 mb-2" />
                  <div className="is-flex has-content-centered">
                    <p
                      className="has-text-weight-bold"
                    >
                      {t('general:integrations')}
                    </p>
                    <p
                      className="has-text-dark has-background-white has-text-weight-semibold px-2 py-1 br4 ml-2"
                    >
                      {`${t('general:new')}!`}
                    </p>
                  </div>
                  <p>
                    {t('general:integrations_description')}

                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="columns is-centered is-vcentered has-background-darker-purple py-6">
          <div className="column is-4-desktop is-5-tablet has-text-centered-mobile">
            <img className="boxshadow" src={gamer} alt="" />
          </div>
          <div className="column has-text-white is-4">
            <p className="is-size-1-desktop is-size-2-tablet is-size-4-mobile has-text-white has-text-weight-bold">
              {`${t('general:for_gamers')}.`}
            </p>
            <p className="has-text-weight-medium">
              {t('general:gamer_text')}
            </p>
            <Link
              to={DEMO}
              type="button"
              className="button has-fullheight grow has-background-darker-purple has-text-white has-text-weight-bold my-3"
            >
              {t('general:create_profile')}
            </Link>
          </div>
        </div>
        <div className="columns py-6 is-centered is-vcentered has-background-dark-purple">
          <div className="column is-4-desktop is-6-tablet has-text-white">
            <p className="is-size-1-desktop is-size-2-tablet is-size-4-mobile has-text-white has-text-weight-bold">
              {`${t('general:for_esports')}.`}
            </p>
            <p className="has-text-weight-medium">
              {t('general:esports_text')}
            </p>
            <Link
              to={DEMO}
              type="button"
              className="button has-fullheight has-text-white grow has-background-dark-purple has-text-weight-bold my-3"
            >
              {t('general:create_profile')}
            </Link>
          </div>
          <div className="column is-4-desktop is-5-tablet has-text-centered-mobile">
            <img className="boxshadow" src={esports} alt="" />
          </div>
        </div>
        <div className="columns is-centered is-vcentered has-background-secondary py-6">
          <div className="column is-4-desktop is-5-tablet has-text-centered-mobile">
            <img className="boxshadow" src={streamer} alt="" />
          </div>
          <div className="column is-4">
            <p className="is-size-1-desktop is-size-2-tablet is-size-4-mobile has-text-white has-text-weight-bold">
              {`${t('general:for_streamers')}.`}
            </p>
            <p className="has-text-weight-medium">
              {t('general:streamer_text')}
            </p>
            <Link
              to={DEMO}
              type="button"
              className="button has-fullheight grow has-background-secondary has-text-weight-bold my-3"
            >
              {t('general:create_profile')}
            </Link>
            <div
              className="mt-5"
            >
              <p className="has-text-weight-semibold">
                {t('general:streamer_use_twitch_integration')}
              </p>
              <img
                className="boxshadow mt-4"
                src={twitch_example}
                alt="Twitch integration example"
              />
            </div>
          </div>
        </div>

        <div className="columns pt-3 ">
          <div className="column has-text-centered">
            <p className="is-size-1-desktop is-size-2-tablet is-size-4-mobile has-text-white has-text-weight-bold mt-3">
              {t('general:for_you')}
            </p>
            <p>
              {t('general:create_account')}
            </p>
            <Link
              to={LOGIN}
              type="button"
              className="button grow has-background-secondary has-text-weight-bold  mt-5"
            >
              {t('general:sign_up_now')}
            </Link>

          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Landing;
