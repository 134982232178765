import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_PROFILE = {
// the "saved" link from the server that is valid and unique
  link: '',
  name: '',
  description: '',
  website: '',
  contact: [],
  profilePic: '',
  backgroundImg: '',
  profileBackgroundImg: '',

  theme: {
    text: {
      color: '#ffffff',
    },
    background: {
      type: 'color',
      values: ['#1C1B29'],
    },
    profile: {
      type: 'color',
      values: ['#292841'],
    },
  },

  showThemeEditor: false,
  connections: {
    twitch: {
      state: '',
      name: '',
      isConnected: false,
    },
  },
};

const initialState = {
  hasData: false,
  isLoading: true,

  // the link in the editor, not yet checked if unique
  currentLink: '',
  ...INITIAL_PROFILE,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  extraReducers: (builder) => {
    // clear creator on CLEAR_STATE
    builder.addCase('CLEAR_STATE', (state) => {
      state = initialState;
      return state;
    });
  },
  reducers: {
    setProfile: (state, action) => {
      const twitchConnection = action.payload.connections ? action.payload.connections.twitch : null;

      return ({
        ...INITIAL_PROFILE,
        ...state,
        hasData: true,
        isLoading: false,
        // if a user has already set a link, use the saved link from the db, otherwise, use the currently set link
        currentLink: action.payload.link ? action.payload.link : state.currentLink,
        ...action.payload,
        connections: {
          twitch: {
            ...state.connections.twitch,
            ...twitchConnection,
          },
        },
      });
    },
    setCurrentLink: (state, action) => {
      state.currentLink = action.payload;
    },
    setShowThemeEditor: (state, action) => {
      state.showThemeEditor = action.payload;
    },
    setBackgroundColor: (state, action) => {
      state.theme.background.values[0] = action.payload;
    },
    setBackgroundType: (state, action) => {
      state.theme.background.type = action.payload;
    },
    setProfileColor: (state, action) => {
      state.theme.profile.values[0] = action.payload;
    },
    setTextColor: (state, action) => {
      state.textColor = action.payload;
      state.theme.text.color = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setWebsite: (state, action) => {
      state.website = action.payload;
    },
    setContact: (state, action) => {
      // idk why, but if we dont check it this breaks
      if (action.payload.id === undefined) return;

      state.contact[action.payload.id].tag = action.payload.tag;
    },
    setContactInfo: (state, action) => {
      state.contact[action.payload.id].info = action.payload.info;
    },
    setContactName: (state, action) => {
      state.contact[action.payload.id].name = action.payload.name;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    swapContact: (state, action) => {
      const { start, end } = action.payload;
      const temp = state.contact[start];

      const newState = state.contact.filter((contact, index) => index !== action.payload.start);

      state.contact = [
        ...newState.slice(0, end),
        temp,
        ...newState.slice(end),
      ];
    },
    addContact: (state, action) => ({
      ...state,
      contact: [...state.contact, { type: action.payload, tag: '', tagType: 'main' }],
    }),
    removeContact: (state, action) => ({
      ...state,
      contact: state.contact.filter((contact, index) => index !== action.payload),
    }),
    setTwitchState: (state, action) => {
      state.connections.twitch.state = action.payload;
    },
    setTwitchConnection: (state, action) => {
      state.connections.twitch = {
        ...state.connections.twitch,
        ...action.payload,
        state: '',
      };
    },
    clearProfile: () => ({
      ...initialState,
      isLoading: false,
    }),
  },
});

export const {
  setCurrentLink,
  setName,
  setDescription,
  setWebsite,

  setBackgroundColor,
  setTextColor,
  setProfileColor,

  setBackgroundType,

  setLoading,
  setShowThemeEditor,

  // Connections
  setTwitchState,
  setTwitchConnection,

  setProfile, clearProfile, addContact, removeContact, setContact, setContactInfo, setContactName, swapContact,
} = profileSlice.actions;

export default profileSlice.reducer;
