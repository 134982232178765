import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import * as de from './de';
import * as en from './en';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  de,
  en,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({

    detection: {
      order: ['navigator', 'htmlTag'],
    },

    resources,

    fallbackLng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export function getCurrentLocale() {
  return i18n.language || window.localStorage.i18nextLng || 'en';
}

export default i18n;
