import React, { useState, useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Hooks
import { useTranslation } from 'react-i18next';

const ContactCopy = (props) => {
  const { text, onClick, color } = props;

  const { t } = useTranslation(['general']);

  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied === false) return undefined;

    const timeout = setTimeout(() => {
      setIsCopied(false);
    }, 700);

    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied]);

  const copyText = () => {
    if (!text) return;

    const { navigator } = window;

    if (!navigator || !navigator.clipboard) {
      return;
    }

    navigator.clipboard.writeText(text);

    setIsCopied(true);

    stats.push(['trackEvent', 'Profile Display', 'Code copied']);

    onClick();
  };

  return (
    <button
      className="button has-no-background has-fullheight px-3 py-2 br-2 has-hover is-inline-block"
      style={{ color, border: `1px solid ${color}` }}
      type="button"
      onClick={copyText}
    >
      {
           isCopied
             ? <i className="fas fa-check is-size-6" />
             : <i className="fas fa-clipboard-list is-size-5" />
      }
    </button>
  );
};

ContactCopy.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

ContactCopy.defaultProps = {
  onClick: () => {},
};

export default ContactCopy;
