import { getCurrentLocale } from 'locales/i18n';
import React from 'react';
import Footer from '../Footer';
import EnglishTOS from './EnglishTOS';
import GermanTOS from './GermanTOS';

const GiveawayTOS = () => {
  const locale = getCurrentLocale();

  return (
    <>
      <div className="box m-5 p-5">
        {
        locale === 'de' ? <GermanTOS /> : <EnglishTOS />
      }
      </div>
      <Footer />
    </>
  );
};

export default GiveawayTOS;
