import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Loader = (props) => {
  const { t } = useTranslation(['general', 'errors']);
  const { error } = props;

  return (
    <div className="hero is-min-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          {
              error
                ? (
                  <p className="has-text-centered has-text-danger has-text-weight-bold is-size-4-desktop is-size-5-touch">
                    {t(`errors:${error}`)}
                  </p>
                )
                : (
                  <p className="is-size-7 has-text-white">
                    {`${t('general:loading')}...`}
                  </p>
                )
          }
        </div>
      </div>
    </div>
  );
};

Loader.propTypes = {
  error: PropTypes.string,
};

Loader.defaultProps = {
  error: '',
};

export default Loader;
