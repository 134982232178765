import React, { useState, useEffect } from 'react';

// libraries
import axios from 'axios';
import { setSession } from 'store/session';
import { clearProfile } from 'store/profile';
import stats from 'analytics/matomo';

// hooks
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux';

// constants
import { HOME, CONNECTIONS_TWITCH_RESPONSE } from 'constants/routes';

// Components
import Loader from 'components/utils/Loader';

const Login = () => {
  const { hasData } = useSelector((state) => state.session);
  const { connections } = useSelector((state) => state.profile);

  const [error, setError] = useState('');

  const {
    getAccessTokenSilently, logout, user: auth0User,
  } = useAuth0();

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [signal] = useState(axios.CancelToken.source());

  useEffect(() => {
    const loadUser = async () => {
      try {
        const token = await getAccessTokenSilently();

        const data = {
          email: auth0User.email,
          name: auth0User.nickname.charAt(0).toUpperCase() + auth0User.nickname.slice(1),
        };

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: signal.token,
        };

        const response = await axios.post(`${process.env.REACT_APP_API}/users`, data, config);

        const { user } = response.data;

        if (!user) {
          logout({ returnTo: window.location.origin });
          return;
        }

        stats.setUserId(user.email);
        stats.push(['trackEvent', 'Account', 'Sign In']);

        dispatch(setSession(user));
        dispatch(clearProfile());

        history.push(HOME);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        }

        console.log(err);

        console.log(err.response);

        if (err.response) {
          setError(err.response.data);
          return;
        }

        if (!err.response) {
          setError('Etwas hat nicht funktioniert. Bitte logge dich erneut ein.');
          return;
        }

        if (err.response.status === 500) {
          setError('Server Fehler. Bitte versuche es später noch einmal.');
          return;
        }
        setError(err.response.data);
      }
    };

    if (!hasData) {
      loadUser();
    } else if (connections.twitch.state) {
      history.push(CONNECTIONS_TWITCH_RESPONSE);
    } else {
      history.push(HOME);
    }

    return () => {
      signal.cancel();
    };
  }, []);

  return (
    <div className="hero is-min-fullheight">
      <div className="hero-body">
        <Loader error={error} />
      </div>
    </div>
  );
};

export default withAuthenticationRequired(Login);
