import React, { useState, useEffect } from 'react';

// Libraries
import PropTypes from 'prop-types';
import stats from 'analytics/matomo';

// Hooks
import useAPI from 'hooks/useAPI';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { setProfile } from 'store/profile';

const ImageUploader = ({ disabled, setDisabled }) => {
  const [imgData, setImgData] = useState();
  const [imgUrl, setImgUrl] = useState();

  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const { post, del } = useAPI();

  const { t } = useTranslation(['general', 'errors']);

  const { backgroundImg } = useSelector((state) => state.profile);

  const saveBackgroundImage = async () => {
    try {
      setError('');
      setDisabled(true);

      const formData = new FormData();
      formData.append('backgroundImg', imgData);

      const profile = await post('/profile/background/', formData);

      dispatch(setProfile(profile));

      stats.push(['trackEvent', 'Profile Editor', 'Added background image']);

      setDisabled(false);
      setImgData();
    } catch (e) {
      setDisabled(false);

      console.log(e);

      setError(t(`errors:${e.response.data}`));
    }
  };

  const removeBackgroundImage = async () => {
    try {
      setError('');
      setDisabled(true);

      const profile = await del('/profile/background/');

      dispatch(setProfile(profile));

      stats.push(['trackEvent', 'Profile Editor', 'Removed background image']);

      setDisabled(false);
      setImgData();
    } catch (e) {
      setDisabled(false);

      console.log(e);

      setError(t(`errors:${e.response.data}`));
    }
  };

  const _setBackgroundImage = (file) => {
    // if file has been rejected, data will be empty
    if (file.length === 0) return;

    setError('');
    setImgData(file[0]);

    URL.revokeObjectURL(imgUrl);
    const url = URL.createObjectURL(file[0]);
    setImgUrl(url);
  };

  const onDropRejected = () => {
    setError(t('errors:error_invalid_file'));
  };

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: _setBackgroundImage,
    maxFiles: 1,
    // max 1 MB
    maxSize: 1048576,
    onDropRejected,
  });

  return (
    <div className="">

      {
          imgUrl
          && (
          <div className="has-max-height-256 has-max-fullwidth has-overflow-hidden p-0 mb-4 is-in-center has-border-white br5 mt-4">
            <img
              src={imgUrl}
              className="has-max-fullwidth has-max-height-256"
              alt=""
            />
          </div>
          )
      }
      {
        !imgUrl && (
          <div {...getRootProps({
            className: 'has-height-200 has-width-200 has-border-white-light is-flex has-content-centered is-in-center mt-5 br5',
          })}
          >
            <input {...getInputProps()} />
            <button
              type="button"
              className="cleanButton has-fullheight has-text-white has-text-weight-bold px-3"
              disabled={disabled}
            >
              <i className="fas fa-image is-block is-size-1 mb-4" />
              {t('general:upload_picture')}
            </button>
          </div>
        )
      }
      {
          imgData
          && (
          <div>
            <p className="mt-4 is-size-7 has-text-white hsa-text-weight-semibold">
              {t('general:image_disclaimer')}
            </p>
            <button
              type="button"
              className={`button has-no-background has-border-white-light has-text-white has-text-weight-bold mt-5 ${disabled ? 'is-loading' : ''}`}
              disabled={disabled}
              onClick={saveBackgroundImage}
            >
              {t('general:save_background_image')}
            </button>
          </div>
          )
        }
      {
          backgroundImg
          && (
          <button
            type="button"
            className={`button has-border-red is-size-7 has-text-underlined has-no-background has-text-white mt-5 ${disabled ? 'is-loading' : ''}`}
            disabled={disabled}
            onClick={removeBackgroundImage}
          >
            {t('general:remove_background_image')}
          </button>
          )
        }
      <p className="has-text-white has-text-weight-bold is-size-7 has-text-centered mt-3">
        {error}
      </p>
    </div>
  );
};

ImageUploader.propTypes = {

  disabled: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
};

export default ImageUploader;
